import {
  Box,
  Center,
  Flex,
  Stack,
  Text,
  FormLabel,
  Button,
  Input,
  InputGroup,
  InputRightElement,
} from "@chakra-ui/react";
import {
  GoogleMap,
  StandaloneSearchBox,
  useJsApiLoader,
} from "@react-google-maps/api";
import environment from "configurations";
import { Form, Formik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import {
  SimulationRequest,
  SimulationResponse,
  SimulatorRideProvider,
  SimulatorTripAttribute,
} from "../models";
import { getPossibleTripAttributes, simulate } from "../service";
import i18next from "i18next";
import { FormActions } from "app/shared/forms/FormActions";
import { observer } from "mobx-react";
import {
  SimulationFormSchema,
  SimulationPoints,
  SimulatorViewModel,
} from "./schema";
import { Select, StyledInput } from "app/shared";
import { Position } from "geojson";
import { Libraries } from "@react-google-maps/api/dist/utils/make-load-script-url";
import { DatePickerInput } from "app/shared/forms/DatePickerInput";
import { GenericSelect } from "app/shared/forms/GenericSelect";
import { GenericInput } from "app/shared/forms/GenericInput";
import { v4 as uuidv4 } from "uuid";
import { TimezoneSelector } from "app/shared/forms/TimezoneSelector";
import { toUtc } from "utils";
import {
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
} from "@chakra-ui/react";
import { InfoOutlineIcon } from "@chakra-ui/icons";
import { handleAxiosError } from "utils/ErrorEventHandler";

interface SimulationFormProps {
  simulationResultHandler: (
    request: SimulationRequest,
    result: SimulationResponse
  ) => void;
}

const SimulationFormBase: React.FC<SimulationFormProps> = ({
  simulationResultHandler,
}) => {
  const [libraries] = useState<Libraries>(["drawing", "places"]);
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: environment.googleMapsApiKey || "",
    libraries,
  });
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [startSearchBox, setStartSearchBox] =
    useState<google.maps.places.SearchBox | null>(null);
  const [endSearchBox, setEndSearchBox] =
    useState<google.maps.places.SearchBox | null>(null);
  const [center] = useState({ lat: 38.685, lng: -115.234 });
  const [customAttributes, setCustomAttributes] = useState<
    SimulatorTripAttribute[]
  >([]);
  const [rideProviders, setRideProviders] = useState<SimulatorRideProvider[]>(
    []
  );
  const [selectedCustomAttributeId, setSelectedCustomAttributeId] = useState<
    string | null
  >(null);
  const [selectedCustomAttributes, setSelectedCustomAttributes] = useState<
    SimulatorTripAttribute[]
  >([]);
  const [simulationPoints, setSimulationPoints] = useState<SimulationPoints>({
    start: { marker: undefined, position: undefined },
    end: { marker: undefined, position: undefined },
  });
  const [startPositionError, setStartPositionError] = useState<string>("");
  const [endPositionError, setEndPositionError] = useState<string>("");
  const [customAttributesError, setCustomAttributesError] =
    useState<string>("");

  useEffect(() => {
    getPossibleTripAttributes()
      .then((setup) => {
        setCustomAttributes(setup.tripAttributes);
        setRideProviders(setup.rideProviders);
      })
      .catch((error) => handleAxiosError(error));
  }, [libraries]);

  const submit = (formValues: SimulatorViewModel) => {
    if (!validDynamicFields()) return;

    const request: SimulationRequest = {
      startDateTime: toUtc(
        formValues.startDateTime as any,
        "YYYY-MM-DD HH:mm",
        formValues.timezone
      ),
      endDateTime: toUtc(
        formValues.endDateTime as any,
        "YYYY-MM-DD HH:mm",
        formValues.timezone
      ),
      rideProvider: formValues.rideProvider,
      user: formValues.user || null,
      startPosition: simulationPoints.start.position!!,
      endPosition: simulationPoints.end.position!!,
      customAttributes: selectedCustomAttributes.map((ta) => {
        return { field: ta.field, value: ta.value };
      }),
      totalTripCost: formValues.totalTripCost ?? null,
    };
    simulate(request)
      .then((result) => simulationResultHandler(request, result))
      .catch((e) => {
        handleAxiosError(e);
      });
  };

  const validDynamicFields = (): boolean => {
    let startError = "",
      endError = "",
      customAttrError = "";
    if (!simulationPoints.start.position || !simulationPoints.end.position) {
      if (!simulationPoints.start.position) {
        startError = i18next.t(
          "simulator:form.simulation.fields.startPosition.required"
        );
      }
      if (!simulationPoints.end.position) {
        endError = i18next.t(
          "simulator:form.simulation.fields.endPosition.required"
        );
      }
    }

    if (selectedCustomAttributes.some((ta) => ta.value.trim() === "")) {
      customAttrError = i18next.t(
        "simulator:form.simulation.fields.customAttributes.empty"
      );
    }

    setStartPositionError(startError);
    setEndPositionError(endError);
    setCustomAttributesError(customAttrError);

    return startError === "" && endError === "" && customAttrError === "";
  };

  const mapOptions: google.maps.MapOptions = {
    disableDefaultUI: true,
    fullscreenControl: true,
    zoomControl: true,
  };

  const onMapReady = (map: google.maps.Map) => {
    setMap(map);
  };

  const startPointSearchBoxReady = (
    searchBox: google.maps.places.SearchBox
  ) => {
    setStartSearchBox(searchBox);
  };

  const endPointSearchBoxReady = (searchBox: google.maps.places.SearchBox) => {
    setEndSearchBox(searchBox);
  };

  const placeChanged = (
    startOrEnd: "start" | "end",
    searchBox: google.maps.places.SearchBox,
    formikProps: any
  ) => {
    const places = searchBox?.getPlaces();
    if (!places || places.length === 0) return;

    // get coordinates searched place coordinates
    const location = places[0].geometry?.location;
    const lat = location?.lat()!!;
    const lng = location?.lng()!!;

    // remove old marker if exists
    const points = Object.assign({}, simulationPoints);
    if (points[startOrEnd].marker) {
      points[startOrEnd].marker?.setMap(null);
    }

    // create a new marker and add it to map
    const marker = new google.maps.Marker({
      position: new google.maps.LatLng(lat, lng),
      map: map as google.maps.Map,
      label: startOrEnd === "start" ? "A" : "B",
      draggable: true,
    });
    google.maps.event.addListener(marker, "dragend", (event: any) =>
      onMarkerDragEnded(formikProps, event, startOrEnd)
    );
    points[startOrEnd].marker = marker;
    points[startOrEnd].position = [lat, lng];

    // update state and map bounds
    setSimulationPoints(points);
    adjustMapBounds();

    // update form field
    const field = startOrEnd === "start" ? "startPosition" : "endPosition";
    formikProps.setFieldValue(field, points[startOrEnd].position);
  };

  const onMarkerDragEnded = (
    formikProps: any,
    event: any,
    startOrEnd: "start" | "end"
  ) => {
    const newLat = event.latLng.lat();
    const newLng = event.latLng.lng();

    const points = Object.assign({}, simulationPoints);
    points[startOrEnd].position = [newLat, newLng];
    setSimulationPoints(points);
    adjustMapBounds();
    const field = startOrEnd === "start" ? "startPosition" : "endPosition";
    formikProps.setFieldValue(field, points[startOrEnd].position);
  };

  const adjustMapBounds = () => {
    const bounds = new google.maps.LatLngBounds();
    if (simulationPoints.start.marker) {
      bounds.extend(simulationPoints.start.marker.getPosition()!!);
    }
    if (simulationPoints.end.marker) {
      bounds.extend(simulationPoints.end.marker.getPosition()!!);
    }
    map!!.fitBounds(bounds);

    // adjust zoom if we only have 1 point
    if (!simulationPoints.start.marker || !simulationPoints.end.marker) {
      const listener = google.maps.event.addListener(map!!, "idle", () => {
        map!!.setZoom(16);
        google.maps.event.removeListener(listener);
      });
    }
  };

  const renderMap = () => (
    <GoogleMap
      id="rideal-drawing-manager"
      mapContainerStyle={{
        height: "250px",
        width: "100%",
      }}
      center={center}
      zoom={8}
      onLoad={onMapReady}
      options={mapOptions}
    ></GoogleMap>
  );

  const selectCustomAttributesOptions = useCallback(() => {
    return customAttributes.map((pa) => {
      return {
        value: pa.id,
        label: pa.name,
      };
    });
  }, [customAttributes]);

  const addCustomAttribute = () => {
    if (!selectedCustomAttributeId) {
      console.warn("No selected attribute to add.");
      return;
    }

    const selectedCustomAttribute = customAttributes.find(
      (ca) => ca.id === selectedCustomAttributeId
    );
    if (!selectedCustomAttribute) {
      console.warn(
        `Could not find custom attribute with id: '${selectedCustomAttributeId}'`
      );
      return;
    }

    if (selectedCustomAttributes.indexOf(selectedCustomAttribute) >= 0) {
      console.info("Custom attribute already selected.");
      return;
    }

    const currentCustomAttributes = selectedCustomAttributes.concat(
      selectedCustomAttribute
    );
    setSelectedCustomAttributes(currentCustomAttributes);
  };

  const removeCustomAttribute = (customAttribute: SimulatorTripAttribute) => {
    const attrIndex = selectedCustomAttributes.indexOf(customAttribute);
    if (attrIndex < 0) {
      console.warn(
        `Could not remove custom attribute with id: '${customAttribute.id}'. Not found.`
      );
      return;
    }
    selectedCustomAttributes.splice(attrIndex, 1);
    const currentCustomAttributes = selectedCustomAttributes.concat([]); // just to force new array and trigger render
    setSelectedCustomAttributes(currentCustomAttributes);
  };

  const formatPosition = (position: Position) =>
    `${position[0].toFixed(6)}, ${position[1].toFixed(6)}`;

  const initialValues: SimulatorViewModel = {
    timezone: environment.defaults.timezone,
    startDateTime: "",
    endDateTime: "",
    startPosition: {
      type: "Feature",
      geometry: { type: "Point", coordinates: [] },
    },
    endPosition: { type: "Feature", geometry: { coordinates: [] } },
    rideProvider: "",
    customAttributes: [],
    user: "",
    totalTripCost: null,
  };

  const labelsWidth = "250px";
  return (
    <Formik
      validationSchema={SimulationFormSchema}
      validateOnChange={false}
      validateOnBlur={false}
      initialValues={initialValues}
      onSubmit={submit}
    >
      {(formikProps) => {
        const onStartDateTimeChanged = (newDate: Date) => {
          const startDate = new Date(newDate);
          const starDateTimestamp = startDate.getTime();

          if (!formikProps.values.endDateTime) {
            formikProps.setFieldValue(
              "endDateTime",
              new Date(starDateTimestamp)
            );
          }
        };

        return (
          <Form>
            <Stack py={3}>
              <Text fontSize="xl" fontWeight="600" mb="3">
                {i18next.t("simulator:form.simulation.title")}
                <Popover gutter={15} isLazy>
                  <PopoverTrigger>
                    <InfoOutlineIcon />
                  </PopoverTrigger>
                  <PopoverContent>
                    <PopoverArrow />
                    <PopoverCloseButton />
                    <PopoverBody fontSize="md" fontWeight="400">
                      {i18next.t("simulator:form.simulation.description")}
                    </PopoverBody>
                  </PopoverContent>
                </Popover>
              </Text>

              <TimezoneSelector
                i18nextPrefix="programmes"
                formName="create"
                fieldName="timezone"
                labelWidth={labelsWidth}
              />

              <DatePickerInput
                i18nextPrefix="simulator"
                formName="simulation"
                fieldName="startDateTime"
                labelWidth={labelsWidth}
                showTime={true}
                onDateChange={(newDate) => {
                  if (typeof newDate !== "object") return;

                  onStartDateTimeChanged(newDate as Date);
                }}
              />

              <DatePickerInput
                i18nextPrefix="simulator"
                formName="simulation"
                fieldName="endDateTime"
                labelWidth={labelsWidth}
                showTime={true}
              />

              <GenericSelect
                i18nextPrefix="simulator"
                formName="simulation"
                fieldName="rideProvider"
                fieldType="string"
                options={rideProviders.map((rp) => {
                  return { value: rp.id, label: rp.name };
                })}
                labelWidth={labelsWidth}
              />

              {/** START POSITION **/}
              <Flex width="100%">
                <Box width={labelsWidth}>
                  <FormLabel>
                    {i18next.t(
                      "simulator:form.simulation.fields.startPosition.label"
                    )}
                  </FormLabel>
                </Box>
                {isLoaded && (
                  <StandaloneSearchBox
                    onLoad={startPointSearchBoxReady}
                    onPlacesChanged={() =>
                      placeChanged("start", startSearchBox!!, formikProps)
                    }
                  >
                    <StyledInput
                      id="startPosition"
                      fontSize="lg"
                      ml="-65px"
                      w="350px"
                      placeholder={i18next.t(
                        "simulator:form.simulation.fields.startPosition.placeholder"
                      )}
                    />
                  </StandaloneSearchBox>
                )}
                <Center fontSize="sm" flex="1">
                  {simulationPoints.start.position &&
                    formatPosition(simulationPoints.start.position)}
                </Center>
              </Flex>
              <Text variant="alert-text">{startPositionError}</Text>

              {/** END POSITION **/}
              <Flex width="100%">
                <Box width={labelsWidth}>
                  <FormLabel>
                    {i18next.t(
                      "simulator:form.simulation.fields.endPosition.label"
                    )}
                  </FormLabel>
                </Box>
                {isLoaded && (
                  <StandaloneSearchBox
                    onLoad={endPointSearchBoxReady}
                    onPlacesChanged={() =>
                      placeChanged("end", endSearchBox!!, formikProps)
                    }
                  >
                    <StyledInput
                      id="endPosition"
                      fontSize="lg"
                      ml="-65px"
                      w="350px"
                      placeholder={i18next.t(
                        "simulator:form.simulation.fields.endPosition.placeholder"
                      )}
                    />
                  </StandaloneSearchBox>
                )}
                <Center fontSize="sm" flex="1">
                  {simulationPoints.end.position &&
                    formatPosition(simulationPoints.end.position)}
                </Center>
              </Flex>
              <Text variant="alert-text">{endPositionError}</Text>
            </Stack>

            {/** MAP **/}
            {isLoaded ? (
              renderMap()
            ) : (
              <Text>{i18next.t("simulator:map.loading")}</Text>
            )}

            {/** USER **/}
            <Box mt={3}>
              <InputGroup size="md">
                <InputRightElement width="6.5rem">
                  <Button
                    size="sm"
                    onClick={() => {
                      formikProps.setFieldValue("user", uuidv4());
                    }}
                  >
                    {i18next.t(
                      "simulator:form.simulation.fields.user.generate"
                    )}
                  </Button>
                </InputRightElement>
              </InputGroup>
              <GenericInput
                i18nextPrefix="simulator"
                formName="simulation"
                fieldName="user"
                fieldType="string"
                labelWidth={labelsWidth}
              />
            </Box>

            {/** TOTAL TRIP PRICE */}
            <GenericInput
              i18nextPrefix="simulator"
              formName="simulation"
              fieldName="totalTripCost"
              fieldType="number"
              labelWidth={labelsWidth}
            />

            {/** CUSTOM ATTRIBUTES **/}
            <Text mt="3">
              {i18next.t(
                "simulator:form.simulation.fields.customAttributes.label"
              )}
            </Text>
            <Stack>
              <Flex>
                <Box flex="1" mr={3} pt={2}>
                  <Select
                    onChange={async (option) =>
                      setSelectedCustomAttributeId(option.value)
                    }
                    options={selectCustomAttributesOptions()}
                  />
                </Box>
                <Button onClick={addCustomAttribute} mt="2">
                  {i18next.t(
                    "simulator:form.simulation.fields.customAttributes.addButton"
                  )}
                </Button>
              </Flex>
              <Box>
                {selectedCustomAttributes.map((ca, index) => {
                  return (
                    <Flex key={index} mb={2}>
                      <Box flex="1">
                        <Flex>
                          <Text flexBasis="100px">{ca.name}</Text>
                          <Input
                            flex="1"
                            placeholder={ca.dataType}
                            onChange={(event) =>
                              (ca.value = event.target.value)
                            }
                          />
                          <Center
                            onClick={() => removeCustomAttribute(ca)}
                            ml={2}
                            _hover={{
                              cursor: "pointer",
                            }}
                          >
                            <span>X</span>
                          </Center>
                        </Flex>
                      </Box>
                    </Flex>
                  );
                })}
              </Box>
            </Stack>
            <Text variant="alert-text">{customAttributesError}</Text>

            <Box mt={3}>
              <FormActions
                hideCancel={true}
                submitText={i18next.t("simulator:form.simulation.submitButton")}
                cancelHandler={() => {}}
              />
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
};

export const SimulationForm = observer(SimulationFormBase);
