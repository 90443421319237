import { Text } from "@chakra-ui/react";
import {
  DrawingManager,
  GoogleMap,
  StandaloneSearchBox,
  useJsApiLoader,
} from "@react-google-maps/api";
import { StyledInput } from "app/shared";
import environment from "configurations";
import { Polygon } from "geojson";
import i18next from "i18next";
import React, { useCallback, useEffect, useRef, useState } from "react";

export type GmapsConfig = "drawing" | "places";
export const LIBRARIES: GmapsConfig[] = ["drawing", "places"];

const toGeoJson = (polygon: google.maps.Polygon) => {
  const vertices = polygon
    .getPath()
    .getArray()
    .flatMap((v) => [[v.lng(), v.lat()]]);
  // Note that in geo-json the last point of the polygon is the same as the first!
  vertices.push(vertices[0]);

  const geoJsonPolygon: Polygon = {
    type: "Polygon",
    coordinates: [vertices],
  };
  return geoJsonPolygon;
};

const calculateBoundingRectangle = (
  gmapsPolygon: google.maps.Polygon
): google.maps.LatLngBounds => {
  const bounds = new google.maps.LatLngBounds();
  gmapsPolygon.getPath().forEach((coords) => {
    bounds.extend(coords);
  });
  return bounds;
};

interface PolygonEditorProps {
  center?: {
    lat: number;
    lng: number;
  };
  mapContainerStyle?: {
    width: string;
    height: string;
  };
  polygon?: Polygon;
  onPolygonUpdate: (polygon: Polygon) => void;
}

export const CatchmentEditor: React.FC<PolygonEditorProps> = (
  props: PolygonEditorProps
) => {
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: environment.googleMapsApiKey || "",
    libraries: LIBRARIES,
  });
  const { onPolygonUpdate } = props;
  const [polygonComplete, setPolygonComplete] = useState(false);
  const [center, setCenter] = useState({
    lat: 38.685,
    lng: -115.234,
  });
  const mapRef = useRef<google.maps.Map>();
  const drawingManagerRef = useRef<google.maps.drawing.DrawingManager>();
  const polygonRef = useRef<google.maps.Polygon>();
  const insertAtListenerRef = useRef<google.maps.MapsEventListener | null>(
    null
  );
  const setAtListenerRef = useRef<google.maps.MapsEventListener | null>(null);
  const removeAtListenerRef = useRef<google.maps.MapsEventListener | null>(
    null
  );
  const startDragListenerRef = useRef<google.maps.MapsEventListener | null>(
    null
  );
  const endDragListenerRef = useRef<google.maps.MapsEventListener | null>(null);
  const resetMapRef = useRef<HTMLDivElement>();
  const [searchBox, setSearchBox] =
    useState<google.maps.places.SearchBox | null>(null);

  // installs the event listeners for the changes done by manipulating the vertices
  const installVertexListeners = useCallback(
    (gmapsPolygon: google.maps.Polygon) => {
      polygonRef.current = gmapsPolygon;
      const setAtListener = google.maps.event.addListener(
        gmapsPolygon.getPath(),
        "set_at",
        () => {
          onPolygonUpdate(toGeoJson(gmapsPolygon));
        }
      );
      setAtListenerRef.current = setAtListener;

      const insertListener = google.maps.event.addListener(
        gmapsPolygon.getPath(),
        "insert_at",
        () => {
          onPolygonUpdate(toGeoJson(gmapsPolygon));
        }
      );
      insertAtListenerRef.current = insertListener;

      const removeAtListener = google.maps.event.addListener(
        gmapsPolygon,
        "rightclick",
        (event: any) => {
          if (event.path != null && event.vertex != null) {
            const path = gmapsPolygon.getPaths().getAt(event.path);
            if (path.getLength() > 3) {
              path.removeAt(event.vertex);
            }
            onPolygonUpdate(toGeoJson(gmapsPolygon));
          }
        }
      );
      removeAtListenerRef.current = removeAtListener;
    },
    [onPolygonUpdate]
  );

  // installs the event listeners that respond to the drag events
  const installDragListeners = useCallback(
    (gmapsPolygon: google.maps.Polygon) => {
      const startDragListener = google.maps.event.addListener(
        gmapsPolygon,
        "dragstart",
        () => {
          const listeners = [
            setAtListenerRef,
            insertAtListenerRef,
            removeAtListenerRef,
          ];
          listeners.forEach((listener) => {
            if (listener.current) {
              google.maps.event.removeListener(listener.current);
            }
          });
        }
      );
      startDragListenerRef.current = startDragListener;

      const endDragListener = google.maps.event.addListener(
        gmapsPolygon,
        "dragend",
        () => {
          installVertexListeners(gmapsPolygon);
          onPolygonUpdate(toGeoJson(gmapsPolygon));
        }
      );
      endDragListenerRef.current = endDragListener;
    },
    [installVertexListeners, onPolygonUpdate]
  );

  // once a polygon is complete we cannot draw any more polygons
  const handleOnPolygonComplete = useCallback(
    (gmapsPolygon: google.maps.Polygon) => {
      polygonRef.current = gmapsPolygon;

      const center = calculateBoundingRectangle(gmapsPolygon).getCenter();
      mapRef.current?.setCenter(center);
      setCenter({ lat: center.lat(), lng: center.lng() });

      installVertexListeners(gmapsPolygon);
      installDragListeners(gmapsPolygon);
      setPolygonComplete(true);
      onPolygonUpdate(toGeoJson(gmapsPolygon));

      // disable the option to draw more!
      drawingManagerRef.current?.setOptions({
        drawingControlOptions: {
          position: google.maps.ControlPosition.TOP_CENTER,
          drawingModes: [],
        },
        drawingMode: null,
      });

      // show reset button
      resetMapRef.current!!.style.display = "block";
    },
    [installDragListeners, installVertexListeners, onPolygonUpdate]
  );

  // if we have an existing polygon, then when we load the map we set that polygon.
  const onMapReady = useCallback((map: google.maps.Map) => {
    mapRef.current = map;

    // Add reset button to the map
    const resetControlDiv = document.createElement("div");
    resetMapRef.current = buildResetButton(resetControlDiv, map);
    map.controls[google.maps.ControlPosition.BOTTOM_CENTER].push(
      resetControlDiv
    );
  }, []);

  // once the drawing manager load, keep a reference for it.
  const onDrawingManagerReady = useCallback(
    (drawingManager: google.maps.drawing.DrawingManager) => {
      drawingManagerRef.current = drawingManager;

      drawingManager.setOptions({
        drawingControlOptions: {
          position: google.maps.ControlPosition.TOP_CENTER,
          drawingModes: [google.maps.drawing.OverlayType.POLYGON],
        },
        polygonOptions: {
          editable: true,
          draggable: true,
        },
      });

      // at this point the map ref has got to exist
      const polygon = props.polygon;
      const map = mapRef.current;
      if (polygon && map) {
        const path: google.maps.LatLngLiteral[] = [];
        for (let i = 0; i < polygon.coordinates[0].length - 1; i++) {
          const coords = polygon.coordinates[0][i];
          path.push({ lng: coords[0], lat: coords[1] });
        }

        const opts: google.maps.PolygonOptions = {
          map: map,
          editable: true,
          draggable: true,
          paths: [path],
        };
        const gmapsPolygon = new google.maps.Polygon(opts);
        handleOnPolygonComplete(gmapsPolygon);

        // set the center of the map
        const boundingRectangle = calculateBoundingRectangle(gmapsPolygon);
        const center = boundingRectangle.getCenter();
        map.setCenter(center);
        setCenter({ lat: center.lat(), lng: center.lng() });
        map.fitBounds(boundingRectangle);

        // show reset button
        resetMapRef.current!!.style.display = "block";
      }
    },
    [props.polygon, handleOnPolygonComplete]
  );

  const placeChanged = () => {
    const places = searchBox?.getPlaces();
    if (!places || places.length === 0) return;

    // get coordinates searched place coordinates
    const location = places[0].geometry?.location;
    mapRef.current?.setCenter(location!!);
  };

  // clear the event listeners on component unmount.
  useEffect(() => {
    if (navigator.geolocation && !props.polygon && !polygonRef.current) {
      navigator.geolocation.getCurrentPosition((position) => {
        setCenter({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
      });
    }

    return () => {
      if (polygonComplete) {
        const listeners = [
          setAtListenerRef,
          insertAtListenerRef,
          startDragListenerRef,
          endDragListenerRef,
          removeAtListenerRef,
        ];
        listeners.forEach((listener) => {
          if (listener.current) {
            google.maps.event.removeListener(listener.current);
          }
        });
      }
    };
  }, [polygonComplete, props.polygon]);

  const mapOptions: google.maps.MapOptions = {
    disableDefaultUI: true,
    fullscreenControl: true,
    zoomControl: true,
  };

  const mapContainerStyle = props.mapContainerStyle || {
    height: "70vh",
    width: "100%",
  };

  const renderMap = () => (
    <GoogleMap
      id="rideal-drawing-manager"
      mapContainerStyle={mapContainerStyle}
      zoom={8}
      onLoad={onMapReady}
      center={center}
      options={mapOptions}
    >
      {isLoaded && (
        <StandaloneSearchBox
          onLoad={(searchBox: google.maps.places.SearchBox) => {
            setSearchBox(searchBox);
          }}
          onPlacesChanged={placeChanged}
        >
          <StyledInput
            id="mapCenterSearch"
            fontSize="lg"
            w="20%"
            background="white"
            placeholder={i18next.t("programmes:catchmentEditor.searchPlace")}
            onKeyDown={(event) => {
              // avoid form submition when selecting a place
              if (event.key === "Enter") {
                event.preventDefault();
              }
            }}
          />
        </StandaloneSearchBox>
      )}
      <DrawingManager
        onLoad={onDrawingManagerReady}
        onPolygonComplete={(polygon) => handleOnPolygonComplete(polygon)}
      />
    </GoogleMap>
  );

  const buildResetButton = (controlDiv: Element, map: google.maps.Map) => {
    // Set CSS for the control border.
    const controlUI = document.createElement("div");
    controlUI.style.backgroundColor = "#fff";
    controlUI.style.border = "2px solid #fff";
    controlUI.style.borderRadius = "3px";
    controlUI.style.boxShadow = "0 2px 6px rgba(0,0,0,.3)";
    controlUI.style.cursor = "pointer";
    controlUI.style.marginTop = "8px";
    controlUI.style.marginBottom = "22px";
    controlUI.style.textAlign = "center";
    controlUI.style.display = "none"; // by default we hide reset button
    controlUI.title = i18next.t("programmes:catchmentEditor.reset");
    controlDiv.appendChild(controlUI);

    // Set CSS for the control interior.
    const controlText = document.createElement("div");
    controlText.style.color = "rgb(25,25,25)";
    controlText.style.fontFamily = "Roboto,Arial,sans-serif";
    controlText.style.fontSize = "16px";
    controlText.style.lineHeight = "38px";
    controlText.style.paddingLeft = "5px";
    controlText.style.paddingRight = "5px";
    controlText.innerHTML = i18next.t("programmes:catchmentEditor.reset");
    controlUI.appendChild(controlText);

    // Setup the click event to clean current polygon
    controlUI.addEventListener("click", () => {
      polygonRef.current?.setMap(null);

      // show polygon option again
      drawingManagerRef.current?.setOptions({
        drawingControlOptions: {
          position: google.maps.ControlPosition.TOP_CENTER,
          drawingModes: [google.maps.drawing.OverlayType.POLYGON],
        },
        polygonOptions: {
          editable: true,
          draggable: true,
        },
      });

      // hide reset button
      controlUI.style.display = "none";
    });
    return controlUI;
  };

  if (loadError) {
    return <Text>{i18next.t("programmes:catchmentEditor.error")}</Text>;
  }

  return isLoaded ? (
    renderMap()
  ) : (
    <Text>{i18next.t("programmes:catchmentEditor.loading")}</Text>
  );
};

interface CatchmentViewerProps {
  mapContainerStyle?: {
    width: string;
    height: string;
  };
  polygon: Polygon;
}

const drawPolygonOnMap = (map: google.maps.Map, polygon: Polygon) => {
  const path: google.maps.LatLngLiteral[] = [];
  for (let i = 0; i < polygon.coordinates[0].length - 1; i++) {
    const coords = polygon.coordinates[0][i];
    path.push({ lng: coords[0], lat: coords[1] });
  }

  const opts: google.maps.PolygonOptions = { map: map, paths: [path] };
  const gmapsPolygon = new google.maps.Polygon(opts);

  // set the center of the map
  const boundingRectangle = calculateBoundingRectangle(gmapsPolygon);
  map.setCenter(boundingRectangle.getCenter());
  map.fitBounds(boundingRectangle);
  return gmapsPolygon;
};

export const CatchmentViewer: React.FC<CatchmentViewerProps> = (
  props: CatchmentViewerProps
) => {
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: environment.googleMapsApiKey || "",
    libraries: LIBRARIES,
  });

  const mapRef = useRef<google.maps.Map | null>(null);
  const gmapsPolyRef = useRef<google.maps.Polygon | null>(null);

  useEffect(() => {
    if (mapRef.current) {
      const gmapsPoly = drawPolygonOnMap(mapRef.current, props.polygon);
      if (gmapsPolyRef.current) {
        gmapsPolyRef.current.setMap(null);
      }
      gmapsPolyRef.current = gmapsPoly;
    }
  }, [props.polygon, mapRef]);

  const onMapReady = useCallback(
    (map: google.maps.Map) => {
      mapRef.current = map;
      const gmapsPoly = drawPolygonOnMap(map, props.polygon);
      if (gmapsPolyRef.current) {
        gmapsPolyRef.current.setMap(null);
      }
      gmapsPolyRef.current = gmapsPoly;
    },
    [props.polygon]
  );

  const mapOptions: google.maps.MapOptions = {
    disableDefaultUI: true,
    fullscreenControl: true,
    zoomControl: true,
  };

  const mapContainerStyle = props.mapContainerStyle || {
    height: "80vh",
    width: "100%",
  };

  const renderMap = () => (
    <GoogleMap
      id="rideal-drawing-manager"
      mapContainerStyle={mapContainerStyle}
      zoom={8}
      onLoad={onMapReady}
      options={mapOptions}
    ></GoogleMap>
  );

  if (loadError) {
    return (
      <Text>{i18next.t("programmes:components.catchmentEditor.error")}</Text>
    );
  }

  return isLoaded ? (
    renderMap()
  ) : (
    <Text>{i18next.t("programmes:components.catchmentEditor.loading")}</Text>
  );
};
