import {
  Box,
  Flex,
  Heading,
  Table,
  Tbody,
  Td,
  Th,
  Tr,
  Text,
  Button,
  Icon,
} from "@chakra-ui/react";
import { BiPencil } from "react-icons/bi";
import i18next from "i18next";
import { observer } from "mobx-react";
import { SortOrder } from "models";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.css";
import React, { useEffect, useState } from "react";
import { formatDate } from "utils";
import { ProgrammeListItem, ProgrammesBudget } from "./models";
import { useProgrammeStoreContext } from "./stores/ProgrammeStore";
import { getProgrammesBudget } from "./services";
import { handleAxiosError } from "utils/ErrorEventHandler";
import { useNavigate } from "react-router-dom";

const ProgrammesTableBase: React.FC = () => {
  const { programmeStore } = useProgrammeStoreContext();
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [budget, setBudget] = useState<ProgrammesBudget[]>([]);
  const [sortField, setSortField] = useState("id");
  const [sortOrder, setSortOrder] = useState<SortOrder>("asc");
  const resultsSize = 30;

  useEffect(() => {
    getProgrammesBudget().then(setBudget).catch(handleAxiosError);
    programmeStore.fetchProgrammes(page, resultsSize, sortField, sortOrder);
  }, [page, programmeStore, resultsSize, sortField, sortOrder]);

  const createdAtTemplate = (rowData: ProgrammeListItem) => {
    return formatDate(rowData.createdAt);
  };

  const buildEditButton = (rowData: ProgrammeListItem) => {
    return (
      <Button
        border="2px"
        borderColor="#12a19a"
        type="button"
        variant="danger"
        onClick={(e: any) => {
          e.stopPropagation();
          programmeStore
            .fetchProgramme(rowData.id)
            .then(() => navigate(`/programs/${rowData.id}/details`))
            .catch(handleAxiosError);
        }}
        title="Edit details"
        value="edit"
      >
        {i18next.t("programmes:programmesTable.dataTable.columns.edit")}
        <Icon as={BiPencil} boxSize="20px" />
      </Button>
    );
  };

  const programmeBudgetTemplate = (budget: ProgrammesBudget, key: number) => {
    return (
      <Table
        key={key}
        className="itemBorderColor"
        width="20rem"
        borderWidth="2px"
        borderRadius="0"
        size="sm"
        variant="simple"
        colorScheme="teal"
      >
        <Tbody>
          <Tr>
            <Th textAlign="center" colSpan={2}>
              {budget.currencyCode}
            </Th>
          </Tr>
          <Tr>
            <Th>{i18next.t("programmes:programmesTable.budget.total")}</Th>
            <Td>
              {budget.total.toFixed(2)}
              {budget.currencyCode}
            </Td>
          </Tr>
          <Tr>
            <Th>{i18next.t("programmes:programmesTable.budget.current")}</Th>
            <Td>
              {budget.remaining.toFixed(2)}
              {budget.currencyCode}
            </Td>
          </Tr>
          <Tr>
            <Th>
              {i18next.t("programmes:programmesTable.budget.remainingPercent")}
            </Th>
            <Td>{((budget.remaining * 100) / budget.total).toFixed(2)}%</Td>
          </Tr>
        </Tbody>
      </Table>
    );
  };

  const selectProgramme = async (id: string) => {
    await programmeStore
      .fetchProgramme(id)
      .then(() => {
        navigate(`/programs/${id}/dashboard`);
      })
      .catch((error) => {
        handleAxiosError(error);
        console.error(error);
      });
  };

  return (
    <Box>
      <Flex flexDir="row" justifyContent="space-between" alignItems="center">
        <Heading mb={4}>
          {i18next.t("programmes:programmesTable.header")}
        </Heading>
      </Flex>
      {programmeStore.programmes.length > 0 && (
        <Flex flexDirection="column" mb="10px">
          <Text fontSize="2xl" mb="5px">
            {i18next.t("programmes:programmesTable.budget.title")}
          </Text>
          <Flex flexDir="row">
            {budget?.map((b, i) => programmeBudgetTemplate(b, i))}
          </Flex>
        </Flex>
      )}
      {programmeStore.state !== "error" && (
        <DataTable
          className="clickable-table"
          rowHover
          onRowClick={(e) => selectProgramme(e.data.id)}
          paginatorTemplate="RowsPerPageDropdown PageLinks FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          paginator
          lazy
          rows={resultsSize}
          value={programmeStore.programmes}
          first={page * resultsSize}
          onPage={(e) => setPage(e.page!)}
          totalRecords={programmeStore.totalProgrammes}
          sortField={sortField}
          sortOrder={sortOrder === "asc" ? 1 : -1}
          onSort={(e) => {
            setSortField(e.sortField);
            setSortOrder(e.sortOrder === 1 ? "asc" : "desc");
          }}
        >
          <Column
            field="name"
            header={i18next.t(
              "programmes:programmesTable.dataTable.columns.name"
            )}
            sortable
          />
          <Column
            field="createdAt"
            header={i18next.t(
              "programmes:programmesTable.dataTable.columns.createdAt"
            )}
            sortable
            sortField="created_at"
            body={createdAtTemplate}
          />
          <Column
            field="currentVersion"
            header={i18next.t(
              "programmes:programmesTable.dataTable.columns.currentVersion"
            )}
          />
          <Column body={buildEditButton}></Column>
        </DataTable>
      )}
    </Box>
  );
};

export const ProgrammesTable = observer(ProgrammesTableBase);
