import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Stack, Text } from "@chakra-ui/react";
import i18next from "i18next";
import { Table, Tbody, Td, Th, Tr, TableCaption } from "@chakra-ui/react";
import { ProgrammeDeploymentCandidate } from "../models";
import { getDeploymentCandidate } from "../services";
import { FormActions } from "app/shared/forms/FormActions";
import { RuleItem, RuleItemType } from "app/rules/models";

interface Props {
  programmeId: string;
  cancelHandler: () => void;
  submitHandler: () => void;
  busy: boolean;
}

export const ruleItemDescription = (item: RuleItem): string => {
  return (
    item.criteria.name +
    " " +
    (item.type === RuleItemType.Geographic
      ? item.isEnd === true && item.isStart === true
        ? `(${i18next.t(
            "deployments:form.createDeployment.origin"
          )}) (${i18next.t("deployments:form.createDeployment.destination")})`
        : item.isEnd === true
        ? `(${i18next.t("deployments:form.createDeployment.destination")})`
        : item.isStart === true
        ? `(${i18next.t("deployments:form.createDeployment.origin")})`
        : " "
      : " ")
  );
};

const CreateDeploymentFormBase: React.FC<Props> = ({
  programmeId,
  cancelHandler,
  submitHandler,
  busy,
}) => {
  const [candidate, setCandidate] = useState<ProgrammeDeploymentCandidate>();

  useEffect(() => {
    getDeploymentCandidate(programmeId).then((cand) => {
      setCandidate(cand);
    });
  }, [programmeId]);

  return (
    <>
      {candidate && (
        <Stack dir="column" spacing="3">
          <Text fontSize="4xl" fontWeight="600">
            {i18next.t("programmes:form.createDeployment.title", {
              name: candidate?.name,
              version: candidate?.version,
            })}
          </Text>

          <Text fontSize="xl" fontWeight="600">
            {i18next.t("programmes:form.createDeployment.details")}
          </Text>
          <Text>
            {i18next.t("programmes:form.createDeployment.name", {
              name: candidate?.name,
            })}
          </Text>

          <Table
            className="itemBorderColor"
            width="40rem"
            borderWidth="2px"
            borderRadius="0"
            size="sm"
            variant="simple"
            colorScheme="teal"
          >
            <TableCaption placement="top">
              {i18next.t("programmes:form.createDeployment.rules")}
            </TableCaption>

            {candidate.rules.map((rule, ruleIndex) => (
              <Tbody key={ruleIndex}>
                <Tr>
                  <Th textAlign="center" colSpan={2}>
                    {i18next.t("programmes:form.createDeployment.rule.title", {
                      name: rule.name,
                    })}
                  </Th>
                </Tr>

                {rule.percentageSubsidy ? (
                  <Tr>
                    {" "}
                    <Th>
                      {i18next.t(
                        "programmes:form.createDeployment.rule.percentageSubsidy"
                      )}
                    </Th>
                    <Td>{rule.baseSubsidy}</Td>
                  </Tr>
                ) : (
                  <Tr>
                    <Th>
                      {" "}
                      {i18next.t(
                        "programmes:form.createDeployment.rule.baseSubsidy"
                      )}
                    </Th>
                    <Td>
                      {rule.baseSubsidy}
                      {candidate.currency}
                    </Td>
                  </Tr>
                )}
                <Tr>
                  <Th>
                    {!rule.percentageSubsidy &&
                      i18next.t(
                        "programmes:form.createDeployment.rule.distanceSubsidy"
                      )}
                  </Th>
                  <Td>
                    {rule.distanceSubsidy}
                    {candidate.currency}{" "}
                  </Td>
                </Tr>
                <Tr>
                  <Th>
                    {!rule.percentageSubsidy &&
                      i18next.t(
                        "programmes:form.createDeployment.rule.maxSubsidy"
                      )}
                  </Th>
                  <Td>
                    {" "}
                    {rule.maxSubsidy}
                    {candidate.currency}
                  </Td>
                </Tr>
                {rule.items.length > 0
                  ? rule.items.map((item, itemIndex) => (
                      <Tr key={itemIndex}>
                        <Th>Criteria {itemIndex + 1}</Th>
                        <Td textAlign="center">{ruleItemDescription(item)}</Td>
                      </Tr>
                    ))
                  : ""}
              </Tbody>
            ))}
          </Table>
          <Text fontSize="2xl" fontWeight="600" align="right">
            {i18next.t(
              "programmes:form.createDeployment.totalAvailableBudget",
              {
                value: candidate.totalAvailableBudget,
                currencyCode: candidate.currency,
              }
            )}
          </Text>
          <FormActions
            submitText={i18next.t("deployments:form.createDeployment.deploy")}
            cancelHandler={cancelHandler}
            submitHandler={submitHandler}
            disableSubmit={busy}
            disableCancel={busy}
          />
        </Stack>
      )}
    </>
  );
};

export const CreateDeploymentForm = observer(CreateDeploymentFormBase);
