import { FormControl, Flex, Box, FormLabel, Text } from "@chakra-ui/react";
import { Field, useField, useFormikContext } from "formik";
import i18next from "i18next";
import React from "react";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../../../styles/date-picker.css";
import { DEFAULT_LABEL_WIDTH, FieldInternalProps } from "./GenericInput";

interface Props {
  fieldName: string;
  formName: string;
  i18nextPrefix: string;
  hideLabel?: boolean;
  disabled?: boolean;
  labelWidth?: string;
  isClearable?: boolean;
  showPopperArrow?: boolean;
  showTime?: boolean;
  onDateChange?: (event: Date | [Date, Date] | null) => void;
}

export const DatePickerInput = (props: Props) => {
  const {
    fieldName,
    formName,
    hideLabel,
    i18nextPrefix,
    showTime,
    onDateChange,
  } = props;

  const { setFieldValue } = useFormikContext();
  const [field] = useField({ ...props, name: fieldName });

  return (
    <Field name={fieldName} type="date" placeholder={fieldName}>
      {(renderProps: FieldInternalProps) => (
        <FormControl
          mb="10px"
          isInvalid={
            renderProps.form.errors[fieldName] &&
            renderProps.form.touched[fieldName]
          }
        >
          <Flex width={"100%"}>
            {!hideLabel && (
              <Box width={props.labelWidth ?? DEFAULT_LABEL_WIDTH} pt={1}>
                <FormLabel htmlFor={fieldName}>
                  {i18next.t(
                    `${i18nextPrefix}:form.${formName}.fields.${fieldName}.label`
                  )}
                </FormLabel>
              </Box>
            )}
            <Flex flexDir="column" width="100%">
              <ReactDatePicker
                {...field}
                {...props}
                autoComplete="off"
                showTimeInput={showTime ?? false}
                selected={(field.value && new Date(field.value)) || null}
                timeFormat="HH:mm"
                dateFormat={showTime ? "yyyy-MM-dd HH:mm" : "dd-MM-yyyy"}
                onChange={(val: any) => {
                  setFieldValue(field.name, val);
                  if (onDateChange) {
                    onDateChange(val);
                  }
                }}
              />

              <Text variant="alert-text">
                {renderProps.form.errors[fieldName]}
              </Text>
            </Flex>
          </Flex>
        </FormControl>
      )}
    </Field>
  );
};
