import { DeploymentDetails } from "app/deployments/DeploymentDetails";
import { Breadcrumb, ContentArea } from "app/shared";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { handleAxiosError } from "utils/ErrorEventHandler";
import { Entity, getEntitiesNames } from "utils/UtilsService";

export const DeploymentDetailsPage: React.FC = () => {
  const { programmeId, deploymentId } = useParams();

  const [programmeName, setProgrammeName] = useState("");
  const [deploymentName, setDeploymentName] = useState("");

  useEffect(() => {
    getEntitiesNames([
      { entity: Entity.Programme, id: programmeId! },
      { entity: Entity.Deployment, id: deploymentId! },
    ])
      .then((response) => {
        setProgrammeName(
          response.find((entry) => entry.id === programmeId)?.name!!
        );
        setDeploymentName(
          response.find((entry) => entry.id === deploymentId)?.name!!
        );
      })
      .catch((error) => {
        handleAxiosError(error);
      });
  }, [programmeId, deploymentId]);

  return (
    <ContentArea>
      <Breadcrumb
        breadcrumbs={[
          {
            path: "/programs",
            i18nLabel: "programmes:programmeDetails.breadcrumbs.programmes",
            selected: false,
          },
          {
            path: `/programs/${programmeId}/dashboard`,
            i18nLabel: "programmes:programmeDetails.breadcrumbs.programme",
            i18nOptions: { value: programmeName },
            selected: true,
          },
          {
            path: `/programs/${programmeId}/deployments/${deploymentId}`,
            i18nLabel: "deployments:details.breadcrumbs.deployment",
            i18nOptions: { value: deploymentName },
            selected: true,
          },
        ]}
      />
      <DeploymentDetails
        programmeId={programmeId!}
        deploymentId={deploymentId!}
      />
    </ContentArea>
  );
};
