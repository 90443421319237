import { makeAutoObservable, runInAction } from "mobx";
import React, { useContext } from "react";
import { fetchAuthSession, JWT } from "@aws-amplify/core";

const formatPermissions = (permissions: string) => {
  const formattedPermissions = permissions.split(",");
  return formattedPermissions;
};

/**
 * Mobx store that keeps track of the authentication state.
 * The `update` action is called when the status of the authentication changes.
 */
class AuthStore {
  idToken?: JWT;
  accessToken?: JWT;

  constructor() {
    makeAutoObservable(this);
  }

  get permissions(): string[] {
    return formatPermissions(
      (this.idToken?.payload["custom:scopes"] as string) ?? ""
    );
  }

  get account() {
    return this.idToken?.payload["custom:account"] as string | undefined;
  }

  get username() {
    return this.idToken?.payload["email"] as string | undefined;
  }

  get user() {
    return this.idToken?.payload as any;
  }

  get id() {
    return this.idToken?.payload["username"] as string | undefined;
  }

  async fetchIdToken() {
    const user = await fetchAuthSession();
    runInAction(() => {
      this.idToken = user.tokens?.idToken;
      this.accessToken = user.tokens?.accessToken;
    });
  }
}

/**
 * React context and provider boilerplate.
 */
export const AuthContext = React.createContext<{ authStore: AuthStore }>({
  authStore: {} as AuthStore,
});

export const useAuthContext = () => useContext(AuthContext);

interface Props {
  children: React.ReactNode;
}

export const AuthProvider: React.FC<Props> = ({ children }) => {
  const authStore = new AuthStore();

  return (
    <AuthContext.Provider value={{ authStore }}>
      {children}
    </AuthContext.Provider>
  );
};
