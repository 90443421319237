import {
  Box,
  Button,
  Grid,
  Icon,
  ListItem,
  Stack,
  Text,
  UnorderedList,
  Table,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
} from "@chakra-ui/react";
import { observer } from "mobx-react";
import React from "react";
import { formatDate } from "utils";
import { useProgrammeStoreContext } from "../stores/ProgrammeStore";
import i18next from "i18next";
import { BiPencil } from "react-icons/bi";
import { NavLink } from "react-router-dom";

interface Props {
  programmeId: string;
}

const ProgrammeDescriptiveInfoBase: React.FC<Props> = ({ programmeId }) => {
  const { programmeStore } = useProgrammeStoreContext();

  const dateTemplate = (date: string | undefined) => {
    return date ? formatDate(date) : "";
  };

  const calculateRemainingBudgetPercent = () => {
    return programmeStore.selectedProgramme
      ? (
          (programmeStore.selectedProgramme!!.remainingBudget * 100) /
          programmeStore.selectedProgramme!!.budget
        ).toFixed(2)
      : null;
  };

  return (
    <>
      <Grid templateColumns="repeat(2, 1fr)">
        <div>
          <Stack direction="row">
            <Text fontSize="2xl" fontWeight="600">
              {programmeStore?.selectedProgramme?.name}
            </Text>
          </Stack>

          <Table
            className="itemBorderColor"
            borderWidth="2px"
            borderRadius="0"
            size="sm"
            variant="simple"
            colorScheme="teal"
          >
            <TableCaption placement="top">Dates</TableCaption>
            <Tbody>
              <Tr>
                <Th>{i18next.t("programmes:dashboard.timezone")}</Th>
                <Td>{programmeStore.selectedProgramme?.timezone}</Td>
              </Tr>
              <Tr>
                <Th>{i18next.t("programmes:dashboard.startDate")}</Th>
                <Td>
                  {dateTemplate(programmeStore.selectedProgramme?.startDate)}
                </Td>
              </Tr>
              <Tr>
                <Th>{i18next.t("programmes:dashboard.endDate")}</Th>
                <Td>
                  {dateTemplate(programmeStore.selectedProgramme?.endDate)}
                </Td>
              </Tr>
            </Tbody>
          </Table>

          <Table
            className="itemBorderColor"
            borderWidth="2px"
            borderRadius="0"
            size="sm"
            variant="simple"
            colorScheme="teal"
          >
            <TableCaption placement="top"> Monetary </TableCaption>
            <Tbody>
              <Tr>
                <Th>{i18next.t("programmes:dashboard.budget")}</Th>
                <Td>{programmeStore.selectedProgramme?.budget}</Td>
              </Tr>
              <Tr>
                <Th>{i18next.t("programmes:dashboard.remainingBudget")}</Th>
                <Td>{calculateRemainingBudgetPercent()} %</Td>
              </Tr>
              <Tr>
                <Th>{i18next.t("programmes:dashboard.currency")}</Th>
                <Td>{programmeStore.selectedProgramme?.currency}</Td>
              </Tr>
            </Tbody>
          </Table>
        </div>
        <div>
          <Box
            maxW="sm"
            className="rideProviderBox"
            borderWidth="2px"
            borderRadius="0"
            overflow="hidden"
          >
            <Box p="1">
              <Box display="flex" alignItems="right"></Box>
              <Box
                mt="1"
                fontWeight="semibold"
                as="h4"
                lineHeight="tight"
                isTruncated
              >
                <Text textAlign="center">
                  {i18next.t("programmes:dashboard.rideProviders")}
                </Text>
                <UnorderedList pl={3}>
                  {programmeStore.selectedProgramme?.rideProviders?.map(
                    (rp) => (
                      <ListItem key={rp.id}>{rp.name}</ListItem>
                    )
                  )}
                </UnorderedList>
              </Box>
            </Box>
          </Box>
        </div>
        <NavLink
          className="dashboardLink"
          to={`/programs/${programmeId}/details`}
        >
          <Button marginTop="3rem" border="2px" borderColor="#12a19a">
            {i18next.t("programmes:dashboard.edit")}
            <Icon as={BiPencil} boxSize="20px" />
          </Button>
        </NavLink>
      </Grid>
    </>
  );
};

export const ProgrammeDescriptiveInfo = observer(ProgrammeDescriptiveInfoBase);
