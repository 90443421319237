import { Box, Text } from "@chakra-ui/react";
import { Form, Formik, FormikHelpers } from "formik";
import React from "react";
import {
  CreateOrUpdateGeoCriteriaFormSchema,
  GeoCriteriaViewModel,
  viewModel2CreateGeoCriteriaCommand,
  viewModel2UpdateGeoCriteriaCommand,
} from "./schema";
import { CatchmentEditor } from "../../maps";
import i18next from "i18next";
import { ErrorResponse, isErrorResponse } from "models";
import { handleFormError } from "app/shared/forms/utils";
import { GeoCriteria } from "../../models";
import { GenericInput } from "app/shared/forms/GenericInput";
import { FormActions } from "app/shared/forms/FormActions";
import { useCriteriaStoresContext } from "app/criteria/stores/CriteriaStore";

export interface CreateGeoCriteriaFormProps {
  programmeId: string;
  onFormSubmitted: () => void;
  onFormSubmitionCanceled: () => void;
  onFormSubmitionError: (error: ErrorResponse, message: string) => void;
}

export const CreateGeoCriteriaForm: React.FC<CreateGeoCriteriaFormProps> = (
  props: CreateGeoCriteriaFormProps
) => {
  const { programmeId } = props;
  const formName = "createGeoCriteria";
  const formI18nPrefix = `programmes:form.${formName}`;
  const { criteriaStore } = useCriteriaStoresContext();

  const submitHandler = async (
    formValues: GeoCriteriaViewModel,
    helpers: FormikHelpers<any>
  ) => {
    if (criteriaStore.state === "pending") return;
    helpers.setSubmitting(true);
    helpers.setSubmitting(true);
    const response = await criteriaStore.createCriteria(
      programmeId,
      viewModel2CreateGeoCriteriaCommand(formValues)
    );
    helpers.setSubmitting(false);
    if (isErrorResponse(response)) {
      handleFormError(
        response,
        helpers,
        props.onFormSubmitionError,
        formI18nPrefix
      );
    } else {
      props.onFormSubmitted();
    }
  };

  const defaultEmptyState: GeoCriteriaViewModel = {
    name: "",
    place: { type: "Polygon", coordinates: [] },
  };

  return (
    <Formik
      initialValues={defaultEmptyState}
      validationSchema={CreateOrUpdateGeoCriteriaFormSchema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={submitHandler}
    >
      {(formikProps) => (
        <Form>
          <Box py={2}>
            <GenericInput
              i18nextPrefix="programmes"
              formName={formName}
              fieldName="name"
              fieldType="string"
              labelWidth="10%"
            />
            <CatchmentEditor
              onPolygonUpdate={(polygon) => {
                formikProps.setFieldValue("place", polygon);
              }}
            />
            <Text variant="alert-text">
              {(formikProps.errors["place"] as any)?.coordinates}
            </Text>
          </Box>
          <FormActions
            submitText={i18next.t(`programmes:form.${formName}.submit`)}
            cancelHandler={props.onFormSubmitionCanceled}
            disableSubmit={criteriaStore.state === "pending"}
          />
        </Form>
      )}
    </Formik>
  );
};

export interface UpdateGeoCriteriaFormProps {
  programmeId: string;
  criteria: GeoCriteria;
  onFormSubmitted: () => void;
  onFormSubmitionCanceled: () => void;
  onFormSubmitionError: (error: ErrorResponse, message: string) => void;
}

export const UpdateGeoCriteriaForm: React.FC<UpdateGeoCriteriaFormProps> = (
  props: UpdateGeoCriteriaFormProps
) => {
  const { criteria, programmeId } = props;

  const formName = "updateGeoCriteria";
  const formI18nPrefix = `programmes:form.${formName}`;
  const { criteriaStore } = useCriteriaStoresContext();

  const submitHandler = async (
    formValues: GeoCriteriaViewModel,
    helpers: FormikHelpers<any>
  ) => {
    if (criteriaStore.state === "pending") return;
    helpers.setSubmitting(true);
    const response = await criteriaStore.updateCriteria(
      programmeId,
      criteria.id,
      viewModel2UpdateGeoCriteriaCommand(criteria.id, formValues)
    );
    helpers.setSubmitting(false);
    if (isErrorResponse(response)) {
      handleFormError(
        response,
        helpers,
        props.onFormSubmitionError,
        formI18nPrefix
      );
    } else {
      props.onFormSubmitted();
    }
  };

  const initialState: any = criteria;
  return (
    <Formik
      initialValues={initialState}
      validationSchema={CreateOrUpdateGeoCriteriaFormSchema}
      onSubmit={submitHandler}
    >
      {(formikProps) => (
        <Form>
          <Box py={2}>
            <GenericInput
              i18nextPrefix="programmes"
              formName={formName}
              fieldName="name"
              fieldType="string"
              labelWidth="10%"
            />

            <CatchmentEditor
              polygon={criteria.place.geometry}
              onPolygonUpdate={(polygon) =>
                formikProps.setFieldValue("place", polygon)
              }
            />
            <Text variant="alert-text">
              {(formikProps.errors["place"] as any)?.coordinates}
            </Text>
          </Box>
          <FormActions
            submitText={i18next.t(`programmes:form.${formName}.submit`)}
            cancelHandler={props.onFormSubmitionCanceled}
            disableSubmit={criteriaStore.state === "pending"}
          />
        </Form>
      )}
    </Formik>
  );
};
