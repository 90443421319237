import { PageResponse, SortOrder } from "models";
import { axios } from "network";
import { Rule, RuleListItem } from "../models";
import { CreateOrUpdateRuleCommand } from "../models/commands";

export const getRulePage = async (
  programmeId: string,
  page: number,
  size: number,
  sortField?: string,
  sortOrder?: SortOrder
) => {
  const paginationParams = `/programmes/${programmeId}/rules?page[number]=${page}&page[size]=${size}${
    sortField && sortOrder ? `&sort[${sortField}]=${sortOrder}` : ""
  }`;
  const rawResponse = await axios().get<PageResponse<RuleListItem>>(
    paginationParams
  );
  return rawResponse.data;
};

export const getRule = async (programmeId: string, id: string) => {
  const response = await axios().get<Rule>(
    `/programmes/${programmeId}/rules/${id}`
  );
  return response.data;
};

export const createRule = async (
  programmeId: string,
  cmd: CreateOrUpdateRuleCommand
) => {
  const response = await axios().post<Rule>(
    `/programmes/${programmeId}/rules`,
    cmd
  );
  return response.data;
};

export const updateRule = async (
  programmeId: string,
  ruleId: string,
  cmd: CreateOrUpdateRuleCommand
) => {
  const response = await axios().put<Rule>(
    `/programmes/${programmeId}/rules/${ruleId}`,
    cmd
  );
  return response.data;
};

export const deleteRule = async (programmeId: string, ruleId: string) => {
  return await axios().delete<void>(
    `/programmes/${programmeId}/rules/${ruleId}`
  );
};
