import React from "react";
import i18next from "i18next";
import { Stack, Button } from "@chakra-ui/react";

export interface FormActionsProps {
  hideCancel?: boolean;
  cancelHandler?: () => void;
  cancelText?: string;
  submitHandler?: () => void;
  submitText?: string;
  disableSubmit?: boolean;
  disableCancel?: boolean;
}

export const FormActions: React.FC<FormActionsProps> = (
  props: FormActionsProps
) => {
  const { cancelText, cancelHandler, submitText } = props;

  return (
    <Stack direction="row-reverse" align="stretch">
      <Button
        type="submit"
        colorScheme="blue"
        onClick={() => {
          props.submitHandler && props.submitHandler();
        }}
        isDisabled={props.disableSubmit === true}
      >
        {submitText ?? i18next.t(`shared:form.submit`)}
      </Button>
      {(props.hideCancel === undefined || props.hideCancel === false) && (
        <Button
          type="reset"
          onClick={() => {
            if (cancelHandler) cancelHandler();
          }}
          isDisabled={props.disableCancel === true}
        >
          {cancelText ?? i18next.t(`shared:form.cancel`)}
        </Button>
      )}
    </Stack>
  );
};
