import { Box, Flex, Heading, Select } from "@chakra-ui/react";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { format } from "utils";
import { useProgrammeStoreContext } from "../stores/ProgrammeStore";
import i18next from "i18next";
import { SortOrder } from "models";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { TripRequest } from "../models";

interface Props {
  programmeId: string;
}

const TripRequestTelemetryBase: React.FC<Props> = ({ programmeId }) => {
  const { programmeStore } = useProgrammeStoreContext();
  const [page, setPage] = useState(0);
  const [sortField, setSortField] = useState("created_at");
  const [sortOrder, setSortOrder] = useState<SortOrder>("desc");
  const [sortType, setSortType] = useState("");
  const resultsSize = 30;

  useEffect(() => {
    programmeStore.fetchProgrammeTripRequests(
      programmeId,
      page,
      resultsSize,
      sortField,
      sortOrder,
      sortType
    );
  }, [programmeId, page, sortOrder, sortField, programmeStore, sortType]);

  const dateTimeTemplate = (row: TripRequest) => {
    return format(row.createdAt, "YYYY-MM-DD HH:mm:ss");
  };
  return (
    <Box>
      <Flex flexDir="row" justifyContent="space-between" alignItems="center">
        <Heading mb={4}>
          {i18next.t("programmes:dashboard.requestsList.title")}
        </Heading>
        <Select
          onChange={(e: any) => setSortType(e.target.value)}
          width="15%"
          placeholder="All"
          size="lg"
        >
          <option value="Confirm">Confirmed</option>
        </Select>
      </Flex>
      {programmeStore.tripRequestState !== "error" && (
        <DataTable
          paginatorTemplate="RowsPerPageDropdown PageLinks FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          paginator
          lazy={true}
          rows={resultsSize}
          value={programmeStore.tripRequests}
          first={page * resultsSize}
          onPage={(e: any) => {
            setPage(e.page);
          }}
          totalRecords={programmeStore.totalTripRequests}
          sortField={sortField}
          sortOrder={sortOrder === "asc" ? 1 : -1}
          onSort={(e) => {
            setSortField(e.sortField);
            setSortOrder(e.sortOrder === 1 ? "asc" : "desc");
          }}
        >
          <Column
            field="createdAt"
            header={i18next.t("programmes:dashboard.requestsList.createdAt")}
            sortable={true}
            sortField="created_at"
            body={dateTimeTemplate}
          />
          <Column
            field={"requestType"}
            header={i18next.t("programmes:dashboard.requestsList.requestType")}
            sortable={true}
            sortField="type"
          />
          <Column
            field="rideProvider"
            header={i18next.t("programmes:dashboard.requestsList.rideProvider")}
          />
          <Column
            field={"totalSubsidies"}
            header={i18next.t(
              "programmes:dashboard.requestsList.totalSubsidies"
            )}
          />
        </DataTable>
      )}
    </Box>
  );
};
export const TripRequestTelemetry = observer(TripRequestTelemetryBase);
