import { extendTheme } from "@chakra-ui/react";

export const layoutSizes = {
  maxWidth: "1440px",
  sideMenu: "300px",
  contentHeaderHeight: "100px",
};

export const customColors = {
  brand: { 500: "#4A5568" },
  primary: {
    "50": "#F0F2F4",
    "100": "#D5DAE1",
    "200": "#BAC2CE",
    "300": "#A0AABB",
    "400": "#8592A8",
    "500": "#6A7A95",
    "600": "#556177",
    "700": "#404959",
    "800": "#2A313C",
    "900": "#15181E",
  },
};

export const theme = extendTheme({
  breakpoints: {
    sm: "544px",
    md: "768px",
    lg: "1024px",
    xl: "1280px",
  },
  sizes: {
    ...layoutSizes,
  },
  colors: {
    ...customColors,
  },
  components: {
    Text: {
      variants: {
        "logo-text": {
          color: "grey.600",
          fontWeight: "bold",
          fontSize: "2xl",
          textTransform: "uppercase",
          py: 4,
        },
        "alert-text": {
          color: "red.500",
          fontWeight: "bold",
        },
        "side-menu-nav": {
          color: "gray.600",
          fontWeight: "bold",
          fontSize: "lg",
          textTransform: "uppercase",
          px: 2,
        },
        "side-menu-subnav": {
          color: "gray.600",
          fontWeight: "bold",
          fontSize: "md",
          textTransform: "uppercase",
        },
        "side-menu-user": {
          fontSize: "md",
          color: "gray.600",
        },
        "side-menu-logout": {
          fontWeight: "bold",
          fontSize: "lg",
        },
      },
    },
    Button: {
      baseStyle: {
        _disabled: {
          backgroundColor: "gray.300",
          color: "black",
        },
        _active: {
          outline: "none",
          boxShadow: "unset",
        },
        _focus: {
          outline: "none",
          boxShadow: "unset",
        },
      },
      variants: {
        primary: {
          borderRadius: 0,
          border: "1px solid",
          borderColor: "black",
          background: "white",
          outline: "none",
          _hover: {
            cursor: "pointer",
            background: "gray.300",
            color: "white",
          },
        },
        secondary: {},
        "modal-btn-cancel": {
          borderRadius: 0,
          backgroundColor: "red.400",
          color: "white",
          _hover: {
            opacity: 0.4,
          },
        },
        "modal-btn-submit": {
          borderRadius: 0,
          backgroundColor: "green.400",
          color: "white",
          _hover: {
            opacity: 0.4,
          },
        },
      },
    },
    CloseButton: {
      baseStyle: {
        _hover: {
          background: "unset",
        },
        _active: {
          outline: "none",
          boxShadow: "unset",
          background: "unset",
        },
        _focus: {
          outline: "none",
          boxShadow: "unset",
        },
      },
    },
  },
});
