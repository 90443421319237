import { Box, Flex, FormControl, FormLabel, Text } from "@chakra-ui/react";
import { Field } from "formik";
import i18next from "i18next";
import React, { useEffect, useState } from "react";
import { Select, SelectOption } from "../select";
import { StyledInput } from "./StyledComponents";

export const DEFAULT_LABEL_WIDTH = "30%";

export interface GenericDoubleProps {
  inputName: string;
  selectName: string;
  formName: string;
  inputType: string;
  selectType: string;
  options: SelectOption[];
  selectedOption?: SelectOption;
  value?: SelectOption;
  i18nextPrefix: string;
  hideLabel?: boolean;
  disabled?: boolean;
  labelWidth?: string;
  inputWidth?: number;
  selectWidth?: number;
  selectClass?: string;
  menuWidth?: number;
  flex?: number;
  onChange?: (option: any) => void;
  placeholderOverride?: string;
  /** override default label */
  labelOverride?: string;
  autoFocus?: boolean;
  className?: string;
}

export interface FieldInternalProps {
  field: any;
  form: any;
}

export const GenericInputSelect: React.FC<GenericDoubleProps> = ({
  inputName,
  selectName,
  formName,
  inputType,
  selectType,
  options,
  selectedOption,
  value,
  autoFocus,
  i18nextPrefix,
  className = "containerInputCombo",
  disabled = false,
  hideLabel = false,
  labelWidth = DEFAULT_LABEL_WIDTH,
  inputWidth,
  selectWidth,
  selectClass = "comboSelect",
  menuWidth,
  flex,
  onChange,
  placeholderOverride,
  labelOverride,
  ...rest
}) => {
  const [selectedValue, setSelectedValue] = useState<SelectOption | undefined>(
    selectedOption
  );

  useEffect(() => {
    if (value) {
      setSelectedValue(value);
    }
  }, [value]);
  return (
    <Flex className={className}>
      {!hideLabel && (
        <Box width={labelWidth ? labelWidth : DEFAULT_LABEL_WIDTH} pt={4.5}>
          <FormLabel htmlFor={inputName}>
            {labelOverride ??
              i18next.t(
                `${i18nextPrefix}:form.${formName}.fields.${inputName}.label`
              )}
          </FormLabel>
        </Box>
      )}
      <Flex flexDir="row" width="100%">
        <Field name={inputName} type={inputType} placeholder={inputName}>
          {(renderProps: FieldInternalProps) => (
            <FormControl
              isInvalid={
                renderProps.form.errors[inputName] &&
                renderProps.form.touched[inputName]
              }
            >
              <StyledInput
                autoFocus={autoFocus === true}
                type={inputType}
                {...renderProps.field}
                id={inputName}
                placeholder={
                  placeholderOverride ??
                  i18next.t(
                    `${i18nextPrefix}:form.${formName}.fields.${inputName}.placeholder`
                  )
                }
                disabled={disabled}
                fontSize="lg"
                px={3}
                py={1}
              />
              <Text variant="alert-text">
                {renderProps.form.errors[inputName]}
              </Text>
            </FormControl>
          )}
        </Field>
        <Field name={selectName} type={selectType}>
          {(renderProps: FieldInternalProps) => (
            <FormControl
              className={selectClass}
              isInvalid={
                renderProps.form.errors[selectName] &&
                renderProps.form.touched[selectName]
              }
            >
              <Select
                className="comboSelect"
                disabled={disabled}
                onChange={(option) => {
                  renderProps.form.setFieldValue(selectName, option.value);
                  renderProps.form.setFieldTouched(selectName, true);
                  if (onChange) {
                    onChange(option);
                  }
                  setSelectedValue(option);
                }}
                options={options}
                defaultValue={selectedOption}
                value={selectedValue ? [selectedValue] : []}
                menuWidth={menuWidth ?? selectWidth}
                width={selectWidth ? `${selectWidth}` : undefined}
                {...rest}
                placeholder={i18next.t(
                  `${i18nextPrefix}:form.${formName}.fields.${selectName}.placeholder`
                )}
              />
              <Text variant="alert-text">
                {renderProps.form.errors[selectName]}
              </Text>
            </FormControl>
          )}
        </Field>
      </Flex>
    </Flex>
  );
};
