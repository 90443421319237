import * as Yup from "yup";
import { GenerateDataCommand } from "../models";

export const DataGeneratorFormSchema = Yup.object({
  nEntries: Yup.number().integer().min(1).required(),
  programmes: Yup.array()
    .of(Yup.string().required())
    .min(1)
    .required("Required"),
}).defined();

export type GenerateDataViewModel = Yup.InferType<
  typeof DataGeneratorFormSchema
>;

export const viewModel2DataGeneratorCommand = (
  formValues: GenerateDataViewModel
): GenerateDataCommand => {
  return {
    nEntries: formValues.nEntries,
    programmes: formValues.programmes,
  };
};
