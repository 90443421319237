import { AxiosInstance, default as Axios } from "axios";
import environment from "configurations";
import { fetchAuthSession } from "@aws-amplify/core";
import { handleAxiosError } from "utils/ErrorEventHandler";

let axiosInstance: AxiosInstance | undefined;

const createAxiosInstance = () => {
  const instance = Axios.create({
    baseURL: `${environment.api}`,
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
  });

  instance.interceptors.request.use(async (configuration) => {
    const user = await fetchAuthSession();
    const idToken = user.tokens?.idToken?.toString();
    configuration.headers.Authorization = `Bearer ${idToken}`;
    return configuration;
  });

  instance.interceptors.response.use(
    async (response) => response,
    async (error) => {
      handleAxiosError(error);
      return Promise.reject(error);
    }
  );

  return instance;
};

export const axios = () => {
  if (!axiosInstance) {
    axiosInstance = createAxiosInstance();
  }

  return axiosInstance;
};
