import { Box, Grid, Stack } from "@chakra-ui/react";
import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { ContentArea } from "app/shared";
import { useProgrammeStoreContext } from "app/programmes/stores/ProgrammeStore";
import { useParams } from "react-router-dom";
import { TripRequestTelemetry } from "./TripRequestTelemetry";
import { ProgrammeDescriptiveInfo } from "./ProgrammeDescriptiveInfo";
import { TripFunnel } from "./TripFunnel";

const ProgrammeDashboardPageBase: React.FC = () => {
  const { programmeStore } = useProgrammeStoreContext();
  const programmeId = useParams().id!;

  useEffect(() => {
    if (programmeStore.selectedProgramme?.id !== programmeId) {
      programmeStore.fetchProgramme(programmeId);
    }
  }, [programmeStore, programmeId]);

  return (
    <ContentArea>
      <Stack dir="column" spacing="3">
        <Grid templateColumns="repeat(2, 1fr)" gap={2}>
          <Box>
            <ProgrammeDescriptiveInfo programmeId={programmeId} />
          </Box>
          <Box>
            <TripFunnel programmeId={programmeId} />
          </Box>
        </Grid>

        <TripRequestTelemetry programmeId={programmeId} />
      </Stack>
    </ContentArea>
  );
};

export const ProgrammeDashboardPage = observer(ProgrammeDashboardPageBase);
