import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Flex,
  Heading,
  useToast,
  PopoverContent,
  PopoverTrigger,
  Popover,
  PopoverArrow,
  PopoverCloseButton,
  PopoverBody,
} from "@chakra-ui/react";
import i18next from "i18next";
import { observer } from "mobx-react";
import { DataTable } from "primereact/datatable";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.css";
import { Column } from "primereact/column";
import { RuleListItem } from "./models";
import React, { useEffect, useState, useRef } from "react";
import { ErrorResponse, isErrorResponse, SortOrder } from "models";
import { BiPencil, BiTrash } from "react-icons/bi";
import { useRuleStoreContext } from "./stores";
import { ManagedModal } from "app/shared/modal";
import { UpdateRuleForm } from "./forms/update";
import { deleteRule } from "./services";
import { ListActionButton } from "app/shared/list/ListActionButton";
import { useProgrammeStoreContext } from "app/programmes/stores/ProgrammeStore";
import { NavLink } from "react-router-dom";
import { InfoOutlineIcon } from "@chakra-ui/icons";

export interface RuleTableProps {
  programmeId: string;
}

const RuleTableBase: React.FC<RuleTableProps> = ({ programmeId }) => {
  const { ruleStore } = useRuleStoreContext();
  const { programmeStore } = useProgrammeStoreContext();
  const [page, setPage] = useState(0);
  const [sortField, setSortField] = useState("name");
  const [sortOrder, setSortOrder] = useState<SortOrder>("asc");
  const resultsSize = 30;
  const toast = useToast();
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [clickedRow, setClickedRow] = useState<RuleListItem | null>(null);
  const [openUpdateRuleModal, setOpenUpdateRuleModal] = useState(false);
  const cancelRef = useRef(null);

  useEffect(() => {
    ruleStore.fetchRules(programmeId, page, resultsSize, sortField, sortOrder);
  }, [ruleStore, programmeId, page, resultsSize, sortField, sortOrder]);

  const onCancelDeleteRule = () => {
    setOpenDeleteDialog(false);
    setClickedRow(null);
  };

  const deleteRuleAction = async () => {
    if (clickedRow !== null) {
      const rule = clickedRow;
      const response = await deleteRule(programmeId, rule.id);
      setClickedRow(null);
      setOpenDeleteDialog(false);
      if (isErrorResponse(response)) {
        toast({
          title: i18next.t("rules:delete.error"),
          status: "error",
          isClosable: true,
        });
      } else {
        toast({
          title: i18next.t("rules:delete.success"),
          status: "success",
          isClosable: true,
        });
        ruleStore.fetchRules(
          programmeId,
          page,
          resultsSize,
          sortField,
          sortOrder
        );
        programmeStore.fetchProgramme(programmeId);
      }
    } else {
      console.warn("Expecting row to have non-null content.");
    }
  };

  const deleteButtonTemplate = (rowData: RuleListItem) => {
    return (
      <ListActionButton
        icon={BiTrash}
        clickHandler={() => {
          setOpenDeleteDialog(true);
          setClickedRow(rowData);
        }}
      />
    );
  };

  const updateButtonTemplate = (rowData: RuleListItem) => {
    return (
      <ListActionButton
        icon={BiPencil}
        clickHandler={() => {
          setClickedRow(rowData);
          setOpenUpdateRuleModal(true);
        }}
      />
    );
  };

  const onRuleUpdateSuccess = () => {
    toast({
      title: i18next.t("rules:form.update.success"),
      status: "success",
      isClosable: true,
    });
    setOpenUpdateRuleModal(false);
    setClickedRow(null);
    // TODO: review these defaults
    ruleStore.fetchRules(programmeId, 0, 30, sortField, sortOrder);
    programmeStore.fetchProgramme(programmeId);
  };

  const onRuleUpdateError = (error: ErrorResponse, msg: string) => {
    toast({ title: msg, status: "error", isClosable: true });
    setOpenUpdateRuleModal(false);
    setClickedRow(null);
  };

  return (
    <>
      <Box>
        <Flex flexDir="row" alignItems="center">
          <Heading m={4}>{i18next.t("rules:ruleTable.header")}</Heading>
          <Popover gutter={15} isLazy>
            <PopoverTrigger>
              <InfoOutlineIcon />
            </PopoverTrigger>
            <PopoverContent>
              <PopoverArrow />
              <PopoverCloseButton />
              <PopoverBody fontSize="md" fontWeight="400">
                {i18next.t("rules:ruleTable.description")}
              </PopoverBody>
            </PopoverContent>
          </Popover>
        </Flex>

        {ruleStore.state !== "error" && (
          <DataTable
            paginatorTemplate="RowsPerPageDropdown PageLinks FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
            paginator
            lazy={true}
            rows={resultsSize}
            value={ruleStore.rules}
            first={page * resultsSize}
            onPage={(e: any) => {
              setPage(e.page);
            }}
            totalRecords={ruleStore.totalRules}
            sortField={sortField}
            sortOrder={sortOrder === "asc" ? 1 : -1}
            onSort={(e) => {
              setSortField(e.sortField);
              setSortOrder(e.sortOrder === 1 ? "asc" : "desc");
            }}
          >
            <Column
              field="name"
              header={i18next.t("rules:ruleTable.columns.name")}
              sortable={true}
            />
            <Column
              body={updateButtonTemplate}
              headerStyle={{ width: "8em", textAlign: "center" }}
              bodyStyle={{ textAlign: "center", overflow: "visible" }}
            />
            <Column
              body={deleteButtonTemplate}
              headerStyle={{ width: "8em", textAlign: "center" }}
              bodyStyle={{ textAlign: "center", overflow: "visible" }}
            />
          </DataTable>
        )}
        <NavLink to={`/programs/${programmeId}/dashboard`}>
          <Button marginTop="3rem" border="2px" borderColor="#12a19a">
            {i18next.t("rules:ruleTable.buttons.back")}
          </Button>
        </NavLink>

        {/* update rule modal */}
        <ManagedModal
          openModal={openUpdateRuleModal}
          title={i18next.t("rules:form.update.title")}
          content={
            <UpdateRuleForm
              programmeId={programmeId}
              ruleId={clickedRow?.id!!}
              onFormSubmitted={onRuleUpdateSuccess}
              onFormSubmitionCanceled={() => setOpenUpdateRuleModal(false)}
              onFormSubmitionError={onRuleUpdateError}
            />
          }
          closeHandler={() => setOpenUpdateRuleModal(false)}
          contentMinHeight={"350px"}
          showMainAction={false}
          showClose={false}
          closeOnOverlayClick={false}
        />
      </Box>

      <AlertDialog
        isOpen={openDeleteDialog}
        leastDestructiveRef={cancelRef}
        onClose={onCancelDeleteRule}
        closeOnEsc={false}
        closeOnOverlayClick={false}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {i18next.t("rules:details.deleteDialog.header")}
            </AlertDialogHeader>

            <AlertDialogBody>
              {i18next.t("rules:details.deleteDialog.body", {
                value: clickedRow?.name,
              })}
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button
                ref={cancelRef}
                onClick={onCancelDeleteRule}
                disabled={ruleStore.state === "pending"}
              >
                {i18next.t("rules:details.deleteDialog.cancel")}
              </Button>
              <Button
                colorScheme="orange"
                onClick={deleteRuleAction}
                ml={3}
                isLoading={ruleStore.state === "pending"}
              >
                {i18next.t("rules:details.deleteDialog.confirm")}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export const RuleTable = observer(RuleTableBase);
