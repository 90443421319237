import React, { useEffect, useState } from "react";
import { Form, Formik, FormikHelpers } from "formik";
import { Box } from "@chakra-ui/react";
import i18next from "i18next";
import {
  CreateOrUpdateRuleFormSchema,
  RuleViewModel,
  viewModel2CreateOrUpdateRuleCommand,
} from "../common/schema";
import { GenericInput } from "app/shared/forms/GenericInput";
import { ErrorResponse, isErrorResponse } from "models";
import { handleFormError } from "app/shared/forms/utils";
import { observer } from "mobx-react";
import { CriteriaSelectionField, RULE_LABELS_WIDTH } from "../common";
import { useRuleStoreContext } from "app/rules/stores";
import { useCriteriaStoresContext } from "app/criteria/stores/CriteriaStore";
import { FormActions } from "app/shared/forms/FormActions";
import { SubsidyUnit, SubsidyUnitComponent } from "../common/subsidy-unit";
import { handleAxiosError } from "utils/ErrorEventHandler";
import { RuleItem } from "app/rules/models";

interface Props {
  programmeId: string;
  ruleId: string;
  onFormSubmitted: () => void;
  onFormSubmitionCanceled: () => void;
  onFormSubmitionError: (errorResponse: ErrorResponse, msg: string) => void;
}

const UpdateRuleFormBase: React.FC<Props> = (props: Props) => {
  const { programmeId, ruleId } = props;

  const formI18nPrefix = "rules:form.update";
  const { criteriaStore } = useCriteriaStoresContext();
  const { ruleStore } = useRuleStoreContext();

  const [, setLoaded] = useState(false);
  const [currentState, setCurrentState] = useState<RuleViewModel>({
    name: "",
    baseSubsidy: 0,
    distanceSubsidy: 0,
    maxSubsidy: 0,
    items: [],
    percentageSubsidy: false,
  });

  useEffect(() => {
    criteriaStore
      .fetchCriteriaPage(programmeId, 0, 10000, "name", "asc")
      .then(() => setLoaded(true))
      .catch((e) => {
        handleAxiosError(e);
      });

    ruleStore
      .fetchRule(programmeId, ruleId)
      .then(() => {
        // in order to satisfy react/yup shady validation, loaded rule items must be declared as any[]
        if (ruleStore.selectedRule) {
          const ruleItems: any[] = [];
          ruleStore.selectedRule.items.forEach((item) => {
            ruleItems.push({
              isStart: item.isStart ? item.isStart : false,
              isEnd: item.isEnd ? item.isEnd : false,
              type: item.type.toString(),
              criteria: {
                id: item.criteria.id,
                name: item.criteria.name,
                type: item.criteria.type,
              },
            });
          });

          setCurrentState({
            name: ruleStore.selectedRule.name,
            baseSubsidy: ruleStore.selectedRule.baseSubsidy,
            distanceSubsidy: ruleStore.selectedRule.distanceSubsidy,
            maxSubsidy: ruleStore.selectedRule.maxSubsidy,
            items: ruleItems,
            percentageSubsidy: ruleStore.selectedRule.percentageSubsidy,
          });
        }
      })
      .catch((error) => {
        handleAxiosError(error);
        console.error(error);
      });
  }, [criteriaStore, ruleStore, programmeId, ruleId]);

  const submitHandler = async (
    formValues: RuleViewModel,
    helpers: FormikHelpers<any>
  ) => {
    if (ruleStore.state === "pending") return;

    helpers.setSubmitting(true);
    const cmd = viewModel2CreateOrUpdateRuleCommand(formValues);
    const response = await ruleStore.updateRule(programmeId, ruleId, cmd);

    if (isErrorResponse(response)) {
      handleFormError(
        response,
        helpers,
        props.onFormSubmitionError,
        formI18nPrefix
      );
    } else {
      props.onFormSubmitted();
    }

    helpers.setSubmitting(false);
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={currentState}
      validationSchema={CreateOrUpdateRuleFormSchema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={submitHandler}
    >
      {(formikProps) => (
        <Form>
          <Box py={2}>
            <GenericInput
              i18nextPrefix="rules"
              formName="common"
              fieldName="name"
              fieldType="string"
              labelWidth={RULE_LABELS_WIDTH}
            />
            <SubsidyUnitComponent
              formikProps={formikProps}
              defaultSusbsidyUnit={
                currentState.percentageSubsidy
                  ? SubsidyUnit.Percentage
                  : SubsidyUnit.MonetaryValue
              }
            />
          </Box>
          <CriteriaSelectionField
            criteria={criteriaStore.criteria}
            initialRuleItems={currentState.items as RuleItem[]}
            onRuleItemListUpdated={(items) =>
              formikProps.setFieldValue("items", items)
            }
            fieldName="items"
          />
          <FormActions
            submitText={i18next.t("rules:form.update.submit")}
            cancelHandler={props.onFormSubmitionCanceled}
          />
        </Form>
      )}
    </Formik>
  );
};

export const UpdateRuleForm = observer(UpdateRuleFormBase);
