import {
  Select,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogBody,
  AlertDialogHeader,
  AlertDialogFooter,
  Button,
  Stack,
  useToast,
  Text,
  Icon,
} from "@chakra-ui/react";
import { observer } from "mobx-react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import React from "react";
import i18next from "i18next";
import { useState, useEffect, useRef } from "react";
import { ManagedModal } from "app/shared/modal";
import { useApiKeyStoreContext } from "../api-keys/stores/ApiKeyStore";
import { isErrorResponse, SortOrder } from "models";
import { CreateApiKeyForm } from "./forms/create";
import { formatToLocalDate } from "utils";
import { Apikey, ApiKeyListItem } from "./models/commands";
import { getApiKey, revokeApiKey } from "./services";
import { useAccountStoreContext } from "../accounts/stores/AccountStore";
import { AccountListItem } from "../accounts/models/commands";
import { BiPencil } from "react-icons/bi";
import { UpdateApiKeyForm } from "./forms/update";

const ApiKeysListBase: React.FC = () => {
  const cancelRef = useRef(null);
  const resultsSize = 30;
  const [page, setPage] = useState(0);
  const [sortField, setSortField] = useState("created_at");
  const [sortOrder, setSortOrder] = useState<SortOrder>("desc");
  const [selectedApiKeyToRevoke, setSelectedApiKeyToRevoke] =
    useState<ApiKeyListItem | null>(null);
  const [selectedApiKeyToUpdate, setSelectedApiKeyToUpdate] =
    useState<Apikey | null>(null);
  const [busy, setBusy] = useState(false);
  const [openCreateApiKeyModal, setOpenCreateApiKeyModal] = useState(false);
  const [openUpdateApiKeyModal, setOpenUpdateApiKeyModal] = useState(false);
  const [openDeleteApiKeyModal, setOpenDeleteApiKeyModal] = useState(false);

  const { apiKeyStore } = useApiKeyStoreContext();
  const { accountStore } = useAccountStoreContext();

  const toast = useToast();

  useEffect(() => {
    apiKeyStore.fetchApiKeys(page, resultsSize, sortField, sortOrder);
    accountStore.fetchAccounts(0, 1000, "name", "asc"); // TODO review these magic numbers
  }, [apiKeyStore, accountStore, page, resultsSize, sortField, sortOrder]);

  const onApiKeyCreatedSuccess = () => {
    toast({
      title: i18next.t("api-keys:form.create.success"),
      status: "success",
      isClosable: true,
    });
    setOpenCreateApiKeyModal(false);
    apiKeyStore.fetchApiKeys(0, 30, sortField, sortOrder); // TODO: review these defaults
  };

  const onApiKeyUpdateSuccess = () => {
    toast({
      title: i18next.t("api-keys:form.update.success"),
      status: "success",
      isClosable: true,
    });
    setOpenUpdateApiKeyModal(false);
    setSelectedApiKeyToUpdate(null);
    apiKeyStore.fetchApiKeys(0, 30, sortField, sortOrder); // TODO: review these defaults
  };

  const dateTemplate = (date: string | undefined) => {
    return date ? formatToLocalDate(date) : "";
  };

  const revokeTemplate = (rowData: ApiKeyListItem) => {
    return rowData.revoked ? (
      <Text
        fontSize="sm"
        title={i18next.t("api-keys:table.revokedAt", {
          date: dateTemplate(rowData.revokedAt),
        })}
      >
        {i18next.t("api-keys:table.revoked")}
      </Text>
    ) : (
      <Button
        colorScheme="red"
        size="xs"
        onClick={() => {
          setSelectedApiKeyToRevoke(rowData);
          setOpenDeleteApiKeyModal(true);
        }}
      >
        {i18next.t("api-keys:table.revoke")}
      </Button>
    );
  };

  const editTemplate = (rowData: ApiKeyListItem) => {
    return rowData.revoked ? (
      <span></span>
    ) : (
      <Button
        type="button"
        onClick={() => {
          getApiKey(rowData.key).then((apiKey) => {
            // shitty way to avoid formik exception for innitial null fields (but not null on schema)
            if (!apiKey.description) apiKey.description = "";
            if (!apiKey.email) apiKey.email = "";

            setSelectedApiKeyToUpdate(apiKey);
            setOpenUpdateApiKeyModal(true);
          });
        }}
        variant="danger"
        title={i18next.t("api-keys:form.update.title")}
      >
        <Icon as={BiPencil} boxSize="20px" />
      </Button>
    );
  };

  const revokeApiKeyAction = async (apiKey: ApiKeyListItem) => {
    setBusy(true);
    const response = await revokeApiKey(apiKey.key);
    if (isErrorResponse(response)) {
      toast({
        title: i18next.t("api-keys:form.revoke.error"),
        status: "error",
        isClosable: true,
      });
    } else {
      toast({
        title: i18next.t("api-keys:form.revoke.success"),
        status: "success",
        isClosable: true,
      });
      apiKeyStore.fetchApiKeys(page, resultsSize, sortField, sortOrder);
    }
    setBusy(false);
    setSelectedApiKeyToRevoke(null);
  };

  const onAccountFilterChanged = (event: any) => {
    const accountId = event.target.value;
    apiKeyStore.fetchApiKeys(
      page,
      resultsSize,
      sortField,
      sortOrder,
      accountId
    );
  };

  return (
    <Stack dir="column" spacing="3">
      <Button onClick={() => setOpenCreateApiKeyModal(true)}>
        {i18next.t("api-keys:table.modal.create.openModal")}
      </Button>

      <Select
        placeholder={i18next.t("api-keys:table.filter.placeholder")}
        onChange={onAccountFilterChanged}
      >
        {accountStore.accounts.map(
          (account: AccountListItem, index: number) => (
            <option value={account.id} key={index}>
              {account.name}
            </option>
          )
        )}
      </Select>

      {apiKeyStore.state !== "error" && (
        <DataTable
          paginatorTemplate="RowsPerPageDropdown PageLinks FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          paginator
          lazy={true}
          rows={resultsSize}
          value={apiKeyStore.apiKeys}
          first={page * resultsSize}
          onPage={(e: any) => setPage(e.page)}
          totalRecords={apiKeyStore.totalApiKeys}
          sortField={sortField}
          sortOrder={sortOrder === "asc" ? 1 : -1}
          onSort={(e) => {
            setSortField(e.sortField);
            setSortOrder(e.sortOrder === 1 ? "asc" : "desc");
          }}
        >
          <Column
            field="key"
            header={i18next.t("api-keys:table.columns.key")}
          />
          <Column
            field="permissions"
            header={i18next.t("api-keys:table.columns.permissions")}
          />
          <Column
            field="description"
            header={i18next.t("api-keys:table.columns.description")}
          />
          <Column
            field="createdAt"
            header={i18next.t("api-keys:table.columns.createdAt")}
            body={(item: ApiKeyListItem) => dateTemplate(item?.createdAt)}
          />
          <Column header="" body={revokeTemplate} />
          <Column header="" body={editTemplate} />
        </DataTable>
      )}

      {/* create api key modal */}
      <ManagedModal
        openModal={openCreateApiKeyModal}
        title={i18next.t("api-keys:form.create.title")}
        content={
          <CreateApiKeyForm
            formSubmitted={onApiKeyCreatedSuccess}
            formSubmittionCanceled={() => {
              setOpenCreateApiKeyModal(false);
            }}
          />
        }
        closeHandler={() => setOpenCreateApiKeyModal(false)}
        contentMinHeight={"350px"}
        showMainAction={false}
        showClose={false}
        closeOnOverlayClick={false}
      />

      {/* update api key modal */}
      <ManagedModal
        openModal={openUpdateApiKeyModal}
        title={i18next.t("api-keys:form.update.title")}
        content={
          <UpdateApiKeyForm
            apiKey={selectedApiKeyToUpdate!!}
            formSubmitted={onApiKeyUpdateSuccess}
            formSubmittionCanceled={() => {
              setOpenUpdateApiKeyModal(false);
              setSelectedApiKeyToUpdate(null);
            }}
          />
        }
        closeHandler={() => setOpenUpdateApiKeyModal(false)}
        contentMinHeight={"350px"}
        showMainAction={false}
        showClose={false}
        closeOnOverlayClick={false}
      />

      {/* revoke api key modal */}
      <AlertDialog
        isOpen={openDeleteApiKeyModal}
        leastDestructiveRef={cancelRef}
        onClose={() => {
          setOpenDeleteApiKeyModal(false);
          setSelectedApiKeyToRevoke(null);
        }}
        closeOnEsc={false}
        closeOnOverlayClick={false}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {i18next.t("api-keys:form.revoke.header")}
            </AlertDialogHeader>

            <AlertDialogBody>
              {i18next.t("api-keys:form.revoke.confirm", {
                apiKey: selectedApiKeyToRevoke?.key,
              })}
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button
                ref={cancelRef}
                onClick={() => {
                  setSelectedApiKeyToRevoke(null);
                  setOpenDeleteApiKeyModal(false);
                }}
                disabled={busy}
              >
                {i18next.t("shared:cancel")}
              </Button>
              <Button
                colorScheme="orange"
                onClick={() => revokeApiKeyAction(selectedApiKeyToRevoke!!)}
                ml={3}
                isLoading={busy}
              >
                {i18next.t("api-keys:form.revoke.confirmButton")}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Stack>
  );
};

export const ApiKeysList = observer(ApiKeysListBase);
