import { ErrorResponse, PageResponse } from "models";
import { SortOrder } from "../../../models/index";
import {
  CreateCriteriaCommand,
  UpdateCriteriaCommand,
} from "../models/commands";
import { axios } from "network";
import { Criteria } from "../models";

export const getCriteriaPage = async (
  programmeId: string,
  page: number,
  size: number,
  sortField?: string,
  sortOrder?: SortOrder
) => {
  const paginationParams = `/programmes/${programmeId}/criteria?page[number]=${page}&page[size]=${size}${
    sortField && sortOrder ? `&sort[${sortField}]=${sortOrder}` : ""
  }`;
  const rawResponse = await axios().get<PageResponse<Criteria>>(
    paginationParams
  );
  return rawResponse.data;
};

export const getCriteria = async (
  programmeId: string,
  id: string
): Promise<Criteria | ErrorResponse> => {
  const response = await axios().get<Criteria>(
    `/programmes/${programmeId}/criteria/${id}`
  );
  return response.data;
};

export const createCriteria = async (
  programmeId: string,
  cmd: CreateCriteriaCommand
) => {
  const response = await axios().post<Criteria>(
    `/programmes/${programmeId}/criteria`,
    cmd
  );
  return response.data;
};

export const updateCriteria = async (
  programmeId: string,
  criteriaId: string,
  cmd: UpdateCriteriaCommand
) => {
  const response = await axios().put<Criteria>(
    `/programmes/${programmeId}/criteria/${criteriaId}`,
    cmd
  );
  return response.data;
};

export const deleteCriteria = async (
  programmeId: string,
  criteriaId: string
) => {
  const reponse = await axios().delete<void>(
    `/programmes/${programmeId}/criteria/${criteriaId}`
  );
  return reponse.data;
};
