import { ProgrammeDetails } from "app/programmes";
import { Breadcrumb, ContentArea } from "app/shared";
import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { handleAxiosError } from "utils/ErrorEventHandler";
import { Events, EventType } from "utils/events";
import { Entity, getEntitiesNames } from "utils/UtilsService";

export const ProgrammeDetailsPage: React.FC = () => {
  const id = useParams().id!;

  const [programmeName, setProgrammeName] = useState("");

  const onProgrammeUpdated = useCallback(() => {
    getEntitiesNames([{ entity: Entity.Programme, id: id }])
      .then((response) => {
        setProgrammeName(response.find((entry) => entry.id === id)?.name!!);
      })
      .catch((error) => {
        handleAxiosError(error);
        console.warn(error);
      });
  }, [id]);

  useEffect(() => {
    onProgrammeUpdated();

    Events.subscribe(EventType.PROGRAMME_UPDATED, onProgrammeUpdated);
    return () => {
      Events.unsubscribe(EventType.PROGRAMME_UPDATED, onProgrammeUpdated);
    };
  }, [id, onProgrammeUpdated]);

  return (
    <ContentArea>
      <Breadcrumb
        breadcrumbs={[
          {
            path: "/programs",
            i18nLabel: "programmes:programmeDetails.breadcrumbs.programmes",
            selected: false,
          },
          {
            path: `/programs/${id}/dashboard`,
            i18nLabel: "programmes:programmeDetails.breadcrumbs.programme",
            i18nOptions: { value: programmeName },
            selected: true,
          },
        ]}
      />

      <ProgrammeDetails programmeId={id} />
    </ContentArea>
  );
};
