import { Box, Center, Grid, Stack, Text } from "@chakra-ui/react";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useProgrammeStoreContext } from "../stores/ProgrammeStore";

interface Props {
  programmeId: string;
}

const TripFunnelBase: React.FC<Props> = ({ programmeId }) => {
  const { programmeStore } = useProgrammeStoreContext();
  const [numRender, setNumRender] = useState(0);

  useEffect(() => {
    setNumRender(numRender + 1);
    programmeStore.fetchTripRequestStats(programmeId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [programmeStore, programmeId]);

  return (
    <>
      <Text fontSize="2xl" fontWeight="600">
        Trip request statistics
      </Text>
      <Grid templateColumns="repeat(3, 1fr)" gap={6}>
        <Box w="100%" p={4} bg="gray.200">
          <Stack>
            <Center>
              <Text fontSize="md">Quotes</Text>
            </Center>
            <Center>
              <Text fontSize="2xl" fontWeight="600">
                {programmeStore.stats.quoteCount} (
                {programmeStore.stats.quotePct.toFixed(2)}%)
              </Text>
            </Center>
          </Stack>
        </Box>
        <Box w="100%" p={4} bg="gray.300">
          <Stack>
            <Center>
              <Text fontSize="md">Initiated</Text>
            </Center>
            <Center>
              <Text fontSize="2xl" fontWeight="600">
                {programmeStore.stats.initiateCount} (
                {programmeStore.stats.initiatePct.toFixed(2)}%)
              </Text>
            </Center>
          </Stack>
        </Box>
        <Box w="100%" p={4} bg="gray.400">
          <Stack>
            <Center>
              <Text fontSize="md">Confirmed</Text>
            </Center>
            <Center>
              <Text fontSize="2xl" fontWeight="600">
                {programmeStore.stats.confirmCount} (
                {programmeStore.stats.confirmPct.toFixed(2)}%)
              </Text>
            </Center>
          </Stack>
        </Box>
      </Grid>
    </>
  );
};

export const TripFunnel = observer(TripFunnelBase);
