import { MainLayout } from "app/shared";
import { SideMenuArea } from "app/shared/layout";
import { SideMenu } from "app/shared/sideMenu";
import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "../styles/aws-styles.css";
import "../styles/rideal-styles.css";
import HomePage from "./HomePage";
import { Login } from "./Login";
import NotFoundPage from "./NotFoundPage";
import { ProgrammeDetailsPage } from "./ProgrammeDetailsPage";
import ProgrammesListPage from "./ProgrammesListPage";
import RideProvidersListPage from "./RideProvidersListPage";
import SubsidizersListPage from "./SubsidizerListPage";
import { DeploymentDetailsPage } from "./DeploymentDetailsPage";
import { Simulator } from "app/simulator/Simulator";
import { DataGenerator } from "app/data-generator/DataGenerator";
import environment from "configurations";
import AccountsListPage from "./admin/AccountsListPage";
import ApiKeysListPage from "./admin/ApiKeysListPage";
import "moment-timezone";
import { ProgrammeDashboardPage } from "app/programmes/dashboard";
import { useToast } from "@chakra-ui/react";
import { registerErrorEventHandler } from "utils/ErrorEventHandler";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { useAuthContext } from "authentication";
import { Amplify } from "aws-amplify";
import "@aws-amplify/ui-react/styles.css";
import awsExports from "../aws-exports";
import { observer } from "mobx-react";

Amplify.configure(awsExports);

function App() {
  const { authStatus } = useAuthenticator((context) => [context.authStatus]);
  const { authStore } = useAuthContext();

  const isAuthenticated = authStatus === "authenticated";
  useEffect(() => {
    if (isAuthenticated) {
      authStore.fetchIdToken();
    }
  }, [isAuthenticated, authStore]);

  return (
    <>
      {registerErrorEventHandler(useToast())}
      <BrowserRouter>
        <Routes>
          {isAuthenticated && !!authStore.idToken ? (
            <Route
              path="/*"
              element={
                <MainLayout>
                  <SideMenuArea>
                    <SideMenu />
                  </SideMenuArea>
                  {/* HOME */}
                  <Routes>
                    <Route index element={<HomePage />} />

                    {/* PROGRAMMES */}
                    <Route path="/programs" element={<ProgrammesListPage />} />
                    <Route
                      path="/programs/:id/details"
                      element={<ProgrammeDetailsPage />}
                    />
                    <Route
                      path="/programs/:id/dashboard"
                      element={<ProgrammeDashboardPage />}
                    />

                    {/* DEPLOYMENTS */}
                    <Route
                      path="/programs/:programmeId/deployments/:deploymentId"
                      element={<DeploymentDetailsPage />}
                    />
                    <Route
                      path="/ride-providers"
                      element={<RideProvidersListPage />}
                    />

                    {/* SUBSIDIZERS */}
                    <Route path="/sponsors" element={<SubsidizersListPage />} />

                    {/* ADMIN - ACCOUNTS */}
                    <Route
                      path="/admin/accounts"
                      element={<AccountsListPage />}
                    />

                    {/* ADMIN - API KEYS */}
                    <Route
                      path="/admin/api-keys"
                      element={<ApiKeysListPage />}
                    />

                    {/* SHOW SIMULATOR ONLY IN PROD */}
                    {environment.production && (
                      <Route path="/simulator" element={<Simulator />} />
                    )}

                    {/* SHOW SIMULATOR AND DATA GENERATOR IN NON-PROD */}
                    {!environment.production && (
                      <Route
                        path="/simulation/simulator"
                        element={<Simulator />}
                      />
                    )}
                    {!environment.production && (
                      <Route
                        path="/simulation/data-generator"
                        element={<DataGenerator />}
                      />
                    )}

                    <Route path="/404" Component={NotFoundPage} />
                  </Routes>
                  {/* <Navigate replace to="/404" /> */}
                </MainLayout>
              }
            ></Route>
          ) : (
            <Route path="*" element={<Login />} />
          )}

          {/* Pages with main layout */}
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default observer(App);
