import React from "react";
import i18next from "i18next";

import {
  CreateOrUpdateApiKeyFormViewModel,
  validateEmail,
  viewModel2UpdateAccountCommand,
} from "./schema";
import { updateApiKey } from "../services";
import { isErrorResponse } from "models";
import { useToast } from "@chakra-ui/react";
import { ApiKeyForm } from "./common";
import { Apikey } from "../models/commands";
import { FormikHelpers } from "formik";

interface UpdateApiKeyFormProps {
  apiKey: Apikey;
  formSubmittionCanceled: () => void;
  formSubmitted: () => void;
}

export const UpdateApiKeyForm: React.FC<UpdateApiKeyFormProps> = (
  props: UpdateApiKeyFormProps
) => {
  const toast = useToast();

  const submitHandler = async (
    formValues: CreateOrUpdateApiKeyFormViewModel,
    helpers: FormikHelpers<any>
  ) => {
    if (!validateEmail(formValues, helpers, props.apiKey.syncedWithCognito))
      return;

    const response = await updateApiKey(
      props.apiKey.key,
      viewModel2UpdateAccountCommand(formValues, props.apiKey.syncedWithCognito)
    );
    if (isErrorResponse(response)) {
      toast({
        title: i18next.t("api-keys:form.update.error"),
        status: "error",
        isClosable: true,
      });
    } else {
      props.formSubmitted();
    }
  };

  return (
    <ApiKeyForm
      initialState={props.apiKey as any}
      syncedWithCognito={props.apiKey.syncedWithCognito}
      updating={true}
      submitHandler={submitHandler}
      cancelHandler={props.formSubmittionCanceled}
    />
  );
};
