import {
  Account,
  AccountListItem,
  CreateOrUpdateAccountCommand,
} from "./../models/commands";
import { PageResponse, SortOrder } from "models";
import { axios } from "network";

export const getAccounts = async (
  page: number,
  size: number,
  sortField?: string,
  sortOrder?: SortOrder
) => {
  const paginationParams = `/admin/accounts?page[number]=${page}&page[size]=${size}${
    sortField && sortOrder ? `&sort[${sortField}]=${sortOrder}` : ""
  }`;
  const rawResponse = await axios().get<PageResponse<AccountListItem>>(
    paginationParams
  );
  return rawResponse.data;
};

export const createAccount = async (cmd: CreateOrUpdateAccountCommand) => {
  return axios()
    .post<Account>("/admin/accounts", cmd)
    .then((response) => response.data);
};

export const deleteAccount = async (accountId: string) => {
  return axios()
    .delete<void>(`/admin/accounts/${accountId}`)
    .then((response) => response.data);
};
