import { PageResponse, SortOrder } from "models";
import { axios } from "network";
import { Subsidizer } from "../models";
import { CreateOrUpdateSubsidizerCommand } from "../models/commands";

export const getSubsidizers = async (
  page: number,
  size: number,
  sortField?: string,
  sortOrder?: SortOrder
) => {
  const paginationParams = `/sponsors?page[number]=${page}&page[size]=${size}${
    sortField && sortOrder ? `&sort[${sortField}]=${sortOrder}` : ""
  }`;
  const rawResponse = await axios().get<PageResponse<Subsidizer>>(
    paginationParams
  );
  return rawResponse.data;
};

export const createSubsidizer = async (
  cmd: CreateOrUpdateSubsidizerCommand
) => {
  const response = await axios().post<Subsidizer>(`/sponsors`, cmd);
  return response.data;
};

export const updateSubsidizer = async (
  subsidizerId: string,
  cmd: CreateOrUpdateSubsidizerCommand
) => {
  const response = await axios().put<Subsidizer>(
    `/sponsors/${subsidizerId}`,
    cmd
  );
  return response.data;
};

export const deleteSubsidizer = async (subsidizerId: string) => {
  return await axios().delete<void>(`/sponsors/${subsidizerId}`);
};
