import { CreateOrUpdateRideProviderCommand } from "./../models/commands";
import { RideProvider } from "./../models/index";
import { axios } from "network";
import { PageResponse } from "models";
import { SortOrder } from "models";
import { RideProviderListItem } from "../models";

export const getRideProviders = async (
  page: number,
  size: number,
  sortField?: string,
  sortOrder?: SortOrder
) => {
  const paginationParams = `/ride-providers?page[number]=${page}&page[size]=${size}${
    sortField && sortOrder ? `&sort[${sortField}]=${sortOrder}` : ""
  }`;
  const rawResponse = await axios().get<PageResponse<RideProviderListItem>>(
    paginationParams
  );
  return rawResponse.data;
};

export const createRideProvider = async (
  cmd: CreateOrUpdateRideProviderCommand
): Promise<RideProvider> => {
  return axios()
    .post<RideProvider>("/ride-providers", cmd)
    .then((response) => response.data);
};

export const updateRideProvider = async (
  rideProviderId: string,
  cmd: CreateOrUpdateRideProviderCommand
): Promise<RideProvider> => {
  return axios()
    .put<RideProvider>(`/ride-providers/${rideProviderId}`, cmd)
    .then((response) => response.data);
};

export const getRideProvider = async (id: string): Promise<RideProvider> => {
  return axios()
    .get<RideProvider>(`/ride-providers/${id}`)
    .then((response) => response.data);
};
