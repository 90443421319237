import { Authenticator } from "@aws-amplify/ui-react";
import { Flex } from "@chakra-ui/react";
import React from "react";

export const Login: React.FC = () => {
  return (
    <Flex justifyContent="center" alignItems="center" height="100vh">
      <Authenticator hideSignUp loginMechanisms={["email"]} />
    </Flex>
  );
};
